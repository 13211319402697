import React, { useEffect, useState } from "react"
import { Link } from "react-router-dom"
import Breadcrumb from "react-bootstrap/Breadcrumb"
import { Modal } from "../common"
import DepartmentForm from "./_department_form"

function App() {
  const [errorMessage, setErrorMessage] = useState("")
  const [modalShow, setModalShow] = useState(false)

  const handleCloseModal = () => {
    setModalShow(false)
  }

  useEffect(() => {
    errorMessage !== "" && setModalShow(true)
  }, [errorMessage])

  useEffect(() => {
    !modalShow && setErrorMessage("")
  }, [modalShow])

  return (
    <>
      <Modal show={modalShow} message={errorMessage} onHide={handleCloseModal} />
      <h1 className="h4">部門新規作成</h1>

      <hr />
      <Breadcrumb className="mb-4 small bg-light">
        <Breadcrumb.Item linkAs="span">
          <Link to="/">ホーム</Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item linkAs="span">
          <Link to="/department/">部門一覧</Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item active>新規作成</Breadcrumb.Item>
      </Breadcrumb>
      <DepartmentForm setErrorMessage={setErrorMessage} />
    </>
  )
}

export default App
