import React, { useCallback, useEffect, useState } from "react"
import { Link, useNavigate } from "react-router-dom"
import Breadcrumb from "react-bootstrap/Breadcrumb"
import Button from "react-bootstrap/Button"
import Form from "react-bootstrap/Form"
import { Admin } from "@/admin"
import { Modal } from "../common"
import { IDepartment, isIDepartmentArray, IAccountAttributes } from "@/lib/interface"
import * as api from "@/lib/api"
import { errorHandle } from "@/lib/system"

enum Progress {
  Preparing,
  Attempting,
}

function App() {
  const [admin, setAdmin] = useState<Admin | null>(null)
  const [progress, setProgress] = useState(Progress.Preparing)
  const [errorMessage, setErrorMessage] = useState("")
  const [modalShow, setModalShow] = useState(false)
  const [email, setEmail] = useState("")
  const [name, setName] = useState("")
  const [inputDepartment, setInputDepartment] = useState("")
  const [departmentsList, setDepartmentsList] = useState<IDepartment[]>([])
  const handleEmailChange = useCallback((e: any) => setEmail(e.target.value), [])
  const handleNameChange = useCallback((e: any) => setName(e.target.value), [])
  const handleDepartmentChange = useCallback((e: any) => setInputDepartment(e.target.value), [])

  const history = useNavigate()

  const handleCloseModal = () => {
    setModalShow(false)
  }

  async function fetchDepartments(): Promise<IDepartment[]> {
    const fetchData = await api.getDepartmentsDb()
    if (!isIDepartmentArray(fetchData.data.json)) {
      return []
    }
    return fetchData.data.json
  }

  // hooks
  useEffect(() => {
    new Admin(setAdmin)
    const initialize = async () => {
      const departmentsList = await fetchDepartments()
      setDepartmentsList(departmentsList)
    }
    initialize()
  }, [])

  useEffect(() => {
    errorMessage !== "" && setModalShow(true)
  }, [errorMessage])

  useEffect(() => {
    !modalShow && setErrorMessage("")
  }, [modalShow])

  const handleSave = async () => {
    setProgress(Progress.Preparing)
    if (!email) {
      setErrorMessage("メールアドレスは必須です")
    } else if (!name) {
      setErrorMessage("氏名は必須です")
    } else if (!inputDepartment) {
      setErrorMessage("部門は必須です")
    }
    if (admin && email && name && inputDepartment) {
      const params = {
        Username: email,
        MessageAction: "SUPPRESS",
        UserAttributes: [
          {
            Name: "email_verified",
            Value: "true",
          },
          {
            Name: "email",
            Value: email,
          },
        ],
      }
      try {
        const createJson = await admin.createAdmin(params)
        if (createJson?.User?.Attributes?.filter((v: IAccountAttributes) => v.Name === "sub")[0]?.Value) {
          const sub = createJson?.User?.Attributes?.filter((v: IAccountAttributes) => v.Name === "sub")[0]?.Value
          const setAdminData = await api.setAdminDataDb(sub, name, inputDepartment)
        }
        history(`/admin/`)
      } catch (err) {
        setErrorMessage(errorHandle(err))
        setProgress(Progress.Attempting)
      }
    }
    setProgress(Progress.Attempting)
  }

  return (
    <>
      <Modal show={modalShow} message={errorMessage} onHide={handleCloseModal} />
      <h1 className="h4">管理者新規作成</h1>
      <hr />
      <Breadcrumb className="mb-4 small bg-light">
        <Breadcrumb.Item linkAs="span">
          <Link to="/">ホーム</Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item linkAs="span">
          <Link to="/admin/">管理者一覧</Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item active>管理者新規作成</Breadcrumb.Item>
      </Breadcrumb>

      <div className="row mb-3">
        <div className="col-md-2 col-12 col-auto col-form-label fw-bold">Eメールアドレス</div>
        <div className="col-md-6 col-12">
          <Form>
            <Form.Control
              type="email"
              name="email"
              value={email}
              placeholder="admin@ptchecksheet.com"
              disabled={progress === Progress.Attempting}
              onChange={handleEmailChange}
            />
          </Form>
        </div>
      </div>

      <div className="row mb-3">
        <div className="col-md-2 col-12 col-auto col-form-label fw-bold">氏名</div>
        <div className="col-md-6 col-12">
          <Form>
            <Form.Control
              type="name"
              name="name"
              value={name}
              placeholder="氏名"
              disabled={progress === Progress.Attempting}
              onChange={handleNameChange}
            />
          </Form>
        </div>
      </div>

      <div className="row mb-3">
        <div className="col-md-2 col-12 col-auto col-form-label fw-bold">部門</div>
        <div className="col-md-4 col-12">
          <Form>
            <Form.Select
              name="department"
              value={inputDepartment}
              placeholder="部門名"
              disabled={progress === Progress.Attempting}
              onChange={handleDepartmentChange}
            >
              {/* 無効な初期値の追加 */}
              <option value="">部門を選択してください</option>
              {departmentsList.map((department: IDepartment) => (
                <option key={department.id} value={department.id}>
                  {department.name}
                </option>
              ))}
            </Form.Select>
          </Form>
        </div>
      </div>

      <div className="row mb-3">
        <div className="col-md-2 col-12 mt-5">
          <Button className="w-100" disabled={progress === Progress.Attempting} onClick={handleSave}>
            追加
          </Button>
        </div>
      </div>
    </>
  )
}

export default App
