import React, { useEffect, useState } from "react"
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import Button from "react-bootstrap/Button"
import Form from "react-bootstrap/Form"
import { IOption } from "@/lib/interface"
import * as api from "@/lib/api"
import { errorHandle } from "@/lib/system"

function App(props: any) {
  const [weathers, setWeathers] = useState<IOption[]>()
  const [contracts, setContracts] = useState<IOption[]>()
  const [departments, setDepartments] = useState<IOption[]>()
  const [constructions, setConstructions] = useState<IOption[]>()
  const [constructionDetails, setConstructionDetails] = useState<IOption[]>()
  const [operators, setOperators] = useState<IOption[]>()
  const [inspections, setInspections] = useState<IOption[]>()

  useEffect(() => {
    getOptions()
  }, [])

  const getOptions = async () => {
    try {
      const weatherJson: any = await api.getWeathersDb()
      if (weatherJson?.data) {
        setWeathers(weatherJson?.data.json)
      }
      const contractJson: any = await api.getContractsDb()
      if (contractJson?.data) {
        setContracts(contractJson?.data.json)
      }
      const departmentJson: any = await api.getDepartmentsDb("", "")
      if (departmentJson?.data) {
        setDepartments(departmentJson?.data.json)
      }
      const constructionJson: any = await api.getConstructionsDb()
      if (constructionJson?.data) {
        setConstructions(constructionJson?.data.json)
      }
      const constructionDetailJson: any = await api.getConstructionDetailsDb()
      if (constructionDetailJson?.data) {
        setConstructionDetails(constructionDetailJson?.data.json)
      }
      const operatorJson: any = await api.getOperatorsDb()
      if (operatorJson?.data) {
        setOperators(operatorJson?.data.json)
      }
      const inspectionJson: any = await api.getInspectionsDb()
      if (inspectionJson?.data) {
        setInspections(inspectionJson?.data.json)
      }
    } catch (err) {
      console.log(errorHandle(err))
    }
  }

  const handleSearch = async () => {
    props.setProgress(props.Progress.Attempting)
    props.getSelect()
    props.setProgress(props.Progress.Preparing)
  }

  return (
    <React.Fragment>
      <Container className="small">
        <Row className="align-items-center">
          <div className="col-12 col-lg-2 fw-bold mb-2 mb-lg-0">部門</div>
          <div className="col-12 col-lg-10">
            <Row>
              {departments?.map((department: IOption, index: number) => {
                return (
                  <div key={`department-${index}`} className="col-6 col-lg-3">
                    <Form.Check
                      inline
                      type="switch"
                      id={`department-${index}`}
                      name="department"
                      label={department.name}
                      value={department.id}
                      checked={props.reportSearch.department_id?.includes(department.id)}
                      onChange={(e) =>
                        props.reportSearchDispatch({
                          type: "DEPARTMENT_ID",
                          payload: e.currentTarget.value,
                          list: departments,
                        })
                      }
                    />
                  </div>
                )
              })}
              <div className="col-6 col-lg-3">
                <Form.Check
                  inline
                  type="switch"
                  id={`department-blank`}
                  name="department"
                  label="入力なし"
                  value="blank"
                  checked={props.reportSearch.department_id?.includes("blank")}
                  onChange={(e) =>
                    props.reportSearchDispatch({
                      type: "DEPARTMENT_ID",
                      payload: e.currentTarget.value,
                      list: departments,
                    })
                  }
                />
              </div>
            </Row>
          </div>
        </Row>
        <hr />
        <Row className="align-items-center">
          <div className="col-12 col-lg-2 fw-bold mb-2 mb-lg-0">JOB番号</div>
          <div className="col-12 col-lg-10">
            <Form className="col-3 col-lg-3 d-inline-block">
              <Form.Control
                size="sm"
                type="text"
                id="job_category"
                name="job_category"
                value={props.reportSearch.job_category}
                placeholder="上2桁"
                disabled={props.progress === props.Progress.Attempting}
                onChange={(e) =>
                  props.reportSearchDispatch({
                    type: "JOB_CATEGORY",
                    payload: e.currentTarget.value,
                  })
                }
                maxLength={10}
              />
            </Form>
            <div className="col-1 col-lg-1 d-inline-block text-center">-</div>
            <Form className="col-8 col-lg-8 d-inline-block">
              <Form.Control
                size="sm"
                type="text"
                id="job_number"
                name="job_number"
                value={props.reportSearch.job_number}
                placeholder="下11桁"
                disabled={props.progress === props.Progress.Attempting}
                onChange={(e) =>
                  props.reportSearchDispatch({
                    type: "JOB_NUMBER",
                    payload: e.currentTarget.value,
                  })
                }
                maxLength={11}
              />
            </Form>
          </div>
        </Row>
        <hr />
        <Row className="align-items-center">
          <div className="col-12 col-lg-2 fw-bold mb-2 mb-lg-0">年月日</div>
          <div className="col-12 col-lg-10">
            <Form>
              <Row className="align-items-center">
                <div className="col-auto">
                  <Form.Control
                    size="sm"
                    type="date"
                    name="job_date_start"
                    id="job_date_start"
                    value={props.reportSearch.job_date_start}
                    placeholder="年月日"
                    disabled={props.progress === props.Progress.Attempting}
                    onChange={(e) =>
                      props.reportSearchDispatch({
                        type: "JOB_DATE_START",
                        payload: e.currentTarget.value,
                      })
                    }
                    maxLength={10}
                  />
                </div>
                <div className="col-auto">〜</div>
                <div className="col-auto">
                  <Form.Control
                    size="sm"
                    type="date"
                    name="job_date_end"
                    id="job_date_end"
                    value={props.reportSearch.job_date_end}
                    placeholder="年月日"
                    disabled={props.progress === props.Progress.Attempting}
                    onChange={(e) =>
                      props.reportSearchDispatch({
                        type: "JOB_DATE_END",
                        payload: e.currentTarget.value,
                      })
                    }
                    maxLength={10}
                  />
                </div>
              </Row>
            </Form>
          </div>
        </Row>
        <hr />
        <Row className="align-items-center">
          <div className="col-12 col-lg-2 fw-bold mb-2 mb-lg-0">天候</div>
          <div className="col-12 col-lg-10">
            <Row>
              {weathers?.map((weather: IOption, index: number) => {
                return (
                  <div key={`weather-${index}`} className="col-4 col-lg-2">
                    <Form.Check
                      inline
                      type="switch"
                      id={`weather-${index}`}
                      name="weather"
                      label={weather.name}
                      value={weather.id}
                      checked={props.reportSearch.weather_id?.includes(weather.id)}
                      onChange={(e) =>
                        props.reportSearchDispatch({
                          type: "WEATHER_ID",
                          payload: e.currentTarget.value,
                          list: weathers,
                        })
                      }
                    />
                  </div>
                )
              })}
              <div className="col-4 col-lg-2">
                <Form.Check
                  inline
                  type="switch"
                  id={`weather-blank`}
                  name="weather"
                  label="入力なし"
                  value="blank"
                  checked={props.reportSearch.weather_id?.includes("blank")}
                  onChange={(e) =>
                    props.reportSearchDispatch({
                      type: "WEATHER_ID",
                      payload: e.currentTarget.value,
                      list: weathers,
                    })
                  }
                />
              </div>
            </Row>
          </div>
        </Row>
        <hr />
        <Row className="align-items-center">
          <div className="col-12 col-lg-2 fw-bold mb-2 mb-lg-0">工事名</div>
          <div className="col-12 col-lg-5">
            <Form>
              <Form.Control
                size="sm"
                type="text"
                id="construction"
                name="construction"
                value={props.reportSearch.construction}
                placeholder="工事名"
                disabled={props.progress === props.Progress.Attempting}
                onChange={(e) =>
                  props.reportSearchDispatch({
                    type: "CONSTRUCTION",
                    payload: e.currentTarget.value,
                  })
                }
                maxLength={40}
              />
            </Form>
          </div>
        </Row>
        <hr />
        <Row className="align-items-center">
          <div className="col-12 col-lg-2 fw-bold mb-2 mb-lg-0">請負区分</div>
          <div className="col-12 col-lg-10">
            <Row>
              {contracts?.map((contract: IOption, index: number) => {
                return (
                  <div key={`contract-${index}`} className="col-4 col-lg-2">
                    <Form.Check
                      inline
                      type="switch"
                      id={`contract-${index}`}
                      name="contract"
                      label={contract.name}
                      value={contract.id}
                      checked={props.reportSearch.contract_id?.includes(contract.id)}
                      onChange={(e) =>
                        props.reportSearchDispatch({
                          type: "CONTRACT_ID",
                          payload: e.currentTarget.value,
                          list: contracts,
                        })
                      }
                    />
                  </div>
                )
              })}
              <div className="col-4 col-lg-2">
                <Form.Check
                  inline
                  type="switch"
                  id={`contract-blank`}
                  name="contract"
                  label="入力なし"
                  value="blank"
                  checked={props.reportSearch.contract_id?.includes("blank")}
                  onChange={(e) =>
                    props.reportSearchDispatch({
                      type: "CONTRACT_ID",
                      payload: e.currentTarget.value,
                      list: contracts,
                    })
                  }
                />
              </div>
            </Row>
          </div>
        </Row>
        <hr />
        <Row className="align-items-center">
          <div className="col-12 col-lg-2 fw-bold mb-2 mb-lg-0">請負金額</div>
          <div className="col-12 col-lg-10">
            <Form>
              <Row className="align-items-center">
                <div className="col-auto">
                  <Form.Control
                    size="sm"
                    type="text"
                    id="contract_amount_start"
                    name="contract_amount_start"
                    value={props.reportSearch.contract_amount_start}
                    placeholder="請負金額"
                    disabled={props.progress === props.Progress.Attempting}
                    onChange={(e) =>
                      props.reportSearchDispatch({
                        type: "CONTRACT_AMOUNT_START",
                        payload: e.currentTarget.value,
                      })
                    }
                    maxLength={11}
                  />
                </div>
                <div className="col-auto">百万円</div>
                <div className="col-auto">〜</div>
                <div className="col-auto">
                  <Form.Control
                    size="sm"
                    type="text"
                    id="contract_amount_end"
                    name="contract_amount_end"
                    value={props.reportSearch.contract_amount_end}
                    placeholder="請負金額"
                    disabled={props.progress === props.Progress.Attempting}
                    onChange={(e) =>
                      props.reportSearchDispatch({
                        type: "CONTRACT_AMOUNT_END",
                        payload: e.currentTarget.value,
                      })
                    }
                    maxLength={11}
                  />
                </div>
                <div className="col-auto">百万円</div>
              </Row>
            </Form>
          </div>
        </Row>
        <hr />
        <Row className="align-items-center">
          <div className="col-12 col-lg-2 fw-bold mb-2 mb-lg-0">部署</div>
          <div className="col-12 col-lg-5">
            <Form>
              <div className="text-danger">{props.reportSearch?.errors?.office_name}</div>
              <Form.Control
                size="sm"
                type="text"
                name="office_name"
                id="office_name"
                value={props.reportSearch.office_name}
                placeholder="部署"
                disabled={props.progress === props.Progress.Attempting}
                onChange={(e) =>
                  props.reportSearchDispatch({
                    type: "OFFICE_NAME",
                    payload: e.currentTarget.value,
                  })
                }
                maxLength={15}
              />
            </Form>
          </div>
        </Row>
        <hr />
        <Row className="align-items-center">
          <div className="col-12 col-lg-2 fw-bold mb-2 mb-lg-0">工事種類</div>
          <div className="col-12 col-lg-10">
            <Row>
              {constructions?.map((construction: IOption, index: number) => {
                return (
                  <div key={`construction-${index}`} className="col-4 col-lg-2">
                    <Form.Check
                      inline
                      type="switch"
                      id={`construction-${index}`}
                      name="construction"
                      label={construction.name}
                      value={construction.id}
                      checked={props.reportSearch.construction_id?.includes(construction.id)}
                      onChange={(e) =>
                        props.reportSearchDispatch({
                          type: "CONSTRUCTION_ID",
                          payload: e.currentTarget.value,
                          list: constructions,
                        })
                      }
                    />
                  </div>
                )
              })}
              <div className="col-4 col-lg-2">
                <Form.Check
                  inline
                  type="switch"
                  id={`construction-blank`}
                  name="construction"
                  label="入力なし"
                  value="blank"
                  checked={props.reportSearch.construction_id?.includes("blank")}
                  onChange={(e) =>
                    props.reportSearchDispatch({
                      type: "CONSTRUCTION_ID",
                      payload: e.currentTarget.value,
                      list: constructions,
                    })
                  }
                />
              </div>
            </Row>
          </div>
        </Row>
        <Row className="align-items-center">
          <div className="col-12 col-lg-2 fw-bold mb-2 mb-lg-0"></div>
          <div className="col-12 col-lg-10">
            <Row>
              {constructionDetails?.map((constructionDetail: IOption, index: number) => {
                return (
                  <div key={`construction-detail-${index}`} className="col-4 col-lg-2">
                    <Form.Check
                      inline
                      type="switch"
                      id={`construction-detail-${index}`}
                      name="construction-detail"
                      label={constructionDetail.name}
                      value={constructionDetail.id}
                      checked={props.reportSearch.construction_detail_id?.includes(constructionDetail.id)}
                      onChange={(e) =>
                        props.reportSearchDispatch({
                          type: "CONSTRUCTION_DETAIL_ID",
                          payload: e.currentTarget.value,
                          list: constructionDetails,
                        })
                      }
                    />
                  </div>
                )
              })}
              <div className="col-4 col-lg-2">
                <Form.Check
                  inline
                  type="switch"
                  id={`construction-detail-blank`}
                  name="construction_detail"
                  label="入力なし"
                  value="blank"
                  checked={props.reportSearch.construction_detail_id?.includes("blank")}
                  onChange={(e) =>
                    props.reportSearchDispatch({
                      type: "CONSTRUCTION_DETAIL_ID",
                      payload: e.currentTarget.value,
                      list: constructionDetails,
                    })
                  }
                />
              </div>
            </Row>
          </div>
        </Row>
        <hr />
        <Row className="align-items-center">
          <div className="col-12 col-lg-2 fw-bold mb-2 mb-lg-0">点検者</div>
          <div className="col-12 col-lg-5">
            <Form>
              <Form.Control
                size="sm"
                type="text"
                name="inspector"
                id="inspector"
                value={props.reportSearch.inspector}
                placeholder="点検者"
                disabled={props.progress === props.Progress.Attempting}
                onChange={(e) =>
                  props.reportSearchDispatch({
                    type: "INSPECTOR",
                    payload: e.currentTarget.value,
                  })
                }
                maxLength={10}
              />
            </Form>
          </div>
        </Row>
        <hr />
        <Row className="align-items-center">
          <div className="col-12 col-lg-2 fw-bold mb-2 mb-lg-0">部長</div>
          <div className="col-12 col-lg-5">
            <Form>
              <Form.Control
                size="sm"
                type="text"
                name="department_manager"
                id="department_manager"
                value={props.reportSearch.department_manager}
                placeholder="部長"
                disabled={props.progress === props.Progress.Attempting}
                onChange={(e) =>
                  props.reportSearchDispatch({
                    type: "DEPARTMENT_MANAGER",
                    payload: e.currentTarget.value,
                  })
                }
                maxLength={10}
              />
            </Form>
          </div>
        </Row>
        <hr />
        <Row className="align-items-center">
          <div className="col-12 col-lg-2 fw-bold mb-2 mb-lg-0">課長</div>
          <div className="col-12 col-lg-5">
            <Form>
              <Form.Control
                size="sm"
                type="text"
                name="section_manager"
                id="section_manager"
                value={props.reportSearch.section_manager}
                placeholder="課長"
                disabled={props.progress === props.Progress.Attempting}
                onChange={(e) =>
                  props.reportSearchDispatch({
                    type: "SECTION_MANAGER",
                    payload: e.currentTarget.value,
                  })
                }
                maxLength={10}
              />
            </Form>
          </div>
        </Row>
        <hr />
        <Row className="align-items-center">
          <div className="col-12 col-lg-2 fw-bold mb-2 mb-lg-0">工事責任者</div>
          <div className="col-12 col-lg-5">
            <Form>
              <Form.Control
                size="sm"
                type="text"
                name="project_manager"
                id="project_manager"
                value={props.reportSearch.project_manager}
                placeholder="工事責任者"
                disabled={props.progress === props.Progress.Attempting}
                onChange={(e) =>
                  props.reportSearchDispatch({
                    type: "PROJECT_MANAGER",
                    payload: e.currentTarget.value,
                  })
                }
                maxLength={10}
              />
            </Form>
          </div>
        </Row>
        <hr />
        <Row className="align-items-center">
          <div className="col-12 col-lg-2 fw-bold mb-2 mb-lg-0">当日の作業員</div>
          <div className="col-12 col-lg-10">
            <Row>
              {operators?.map((operator: IOption, index: number) => {
                return (
                  <div key={`operator-${index}`} className="col-4 col-lg-2">
                    <Form.Check
                      inline
                      type="switch"
                      id={`operator-${index}`}
                      name="operator"
                      label={operator.name}
                      value={operator.id}
                      checked={props.reportSearch.operators?.includes(operator.id)}
                      onChange={(e) =>
                        props.reportSearchDispatch({
                          type: "OPERATOR_ID",
                          payload: e.currentTarget.value,
                          list: operators,
                        })
                      }
                    />
                  </div>
                )
              })}
              <div className="col-4 col-lg-2">
                <Form.Check
                  inline
                  type="switch"
                  id={`operator-blank`}
                  name="operator"
                  label="入力なし"
                  value="blank"
                  checked={props.reportSearch.operators?.includes("blank")}
                  onChange={(e) =>
                    props.reportSearchDispatch({
                      type: "OPERATOR_ID",
                      payload: e.currentTarget.value,
                      list: operators,
                    })
                  }
                />
              </div>
            </Row>
          </div>
        </Row>
        <hr />
        <Row className="align-items-center">
          <div className="col-12 col-lg-2 fw-bold mb-2 mb-lg-0">
            建築会社
            <br className="d-none d-lg-block" />
            （統括安全衛生責任者）
          </div>
          <div className="col-12 col-lg-5">
            <Form>
              <Form.Control
                size="sm"
                type="text"
                name="construction_company"
                id="construction_company"
                value={props.reportSearch.construction_company}
                placeholder="建築会社（統括安全衛生責任者）"
                disabled={props.progress === props.Progress.Attempting}
                onChange={(e) =>
                  props.reportSearchDispatch({
                    type: "CONSTRUCTION_COMPANY",
                    payload: e.currentTarget.value,
                  })
                }
                maxLength={15}
              />
            </Form>
          </div>
        </Row>
        <hr />
        <Row className="align-items-center">
          <div className="col-12 col-lg-2 fw-bold mb-2 mb-lg-0">当日入場協力会社</div>
          <div className="col-12 col-lg-5">
            <Form>
              <Form.Control
                type="text"
                name="partners"
                id="partners"
                value={props.reportSearch.partners}
                placeholder="当日入場協力会社"
                disabled={props.progress === props.Progress.Attempting}
                onChange={(e) =>
                  props.reportSearchDispatch({
                    type: "PARTNER",
                    payload: e.currentTarget.value,
                  })
                }
                maxLength={15}
              />
            </Form>
          </div>
        </Row>
        <hr />
        <Row className="align-items-center">
          <div className="col-12 col-lg-2 fw-bold mb-2 mb-lg-0">コメント</div>
          <div className="col-12 col-lg-5">
            <Form>
              <Form.Control
                size="sm"
                type="text"
                name="comment"
                id="comment"
                value={props.reportSearch.comment}
                placeholder="コメント"
                disabled={props.progress === props.Progress.Attempting}
                onChange={(e) =>
                  props.reportSearchDispatch({
                    type: "COMMENT",
                    payload: e.currentTarget.value,
                  })
                }
                maxLength={50}
              />
            </Form>
          </div>
        </Row>
        <hr />
        <Row className="align-items-center">
          <div className="col-12 col-lg-2 fw-bold mb-2 mb-lg-0">点検分類</div>
          <div className="col-12 col-lg-10">
            <Row>
              {inspections?.map((inspection: IOption, index: number) => {
                return (
                  <div key={`inspection-${index}`} className="col-6 col-lg-4">
                    <Form.Check
                      inline
                      type="switch"
                      id={`inspection-${index}`}
                      name="inspection"
                      label={inspection.name}
                      value={inspection.id}
                      checked={props.reportSearch.inspections?.includes(inspection.id)}
                      onChange={(e) =>
                        props.reportSearchDispatch({
                          type: "INSPECTION_ID",
                          payload: e.currentTarget.value,
                          list: inspections,
                        })
                      }
                    />
                  </div>
                )
              })}
            </Row>
          </div>
        </Row>
        <hr />
        <div className="text-center mb-3 mt-4">
          <Button
            className="text-right w-75"
            disabled={props.progress === props.Progress.Attempting}
            onClick={handleSearch}
          >
            検索
          </Button>
        </div>
      </Container>
    </React.Fragment>
  )
}

export default App
