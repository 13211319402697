import React from "react"
import { createRoot } from "react-dom/client"

import "bootstrap/dist/css/bootstrap.min.css"
import "./css/css"

import App from "./app"
import Amplify from "aws-amplify"
import config from "./config/config"
import "./lib/i18n/config"

Amplify.configure(config)

const container = document.getElementById("root")
const root = createRoot(container!)

root.render(<App />)
