import React, { useEffect, useState } from "react"
import Button from "react-bootstrap/Button"
import Modal from "react-bootstrap/Modal"
import Row from "react-bootstrap/Row"
import { UserData } from "@/lib/interface"
import * as api from "@/lib/api"
import { errorHandle } from "@/lib/system"

export default (props: any) => {
  const [errorMessage, setErrorMessage] = useState("")
  useEffect(() => {
    setErrorMessage("")
  }, [props.show])

  const handleDelete = async () => {
    const params = {
      Username: props.delUserId,
    }
    try {
      await props.admin?.deleteUser(params)
      await api.delPasswordDb(props.delUserSub, props.delUserId)
      const updateUsers = props.users?.filter((user: UserData) => {
        return user.user_id !== props.delUserId
      })
      props.setUsers(updateUsers)
      props.onHide()
    } catch (err) {
      setErrorMessage(errorHandle(err))
      console.log(err)
    }
  }

  return (
    <Modal show={props.show} onHide={props.onHide}>
      <Modal.Title id="contained-modal-title-vcenter"></Modal.Title>
      <Modal.Body>
        <div>{errorMessage}</div>
        <Row>
          <div className="col-6">
            <Button onClick={() => handleDelete()} className="btn-sm w-100">
              <span>削除しますか?</span>
            </Button>
          </div>
          <div className="col-6">
            <Button onClick={props.onHide} className="btn-sm w-100" variant="secondary">
              Cancel
            </Button>
          </div>
        </Row>
      </Modal.Body>
    </Modal>
  )
}
