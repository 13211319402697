import React from "react"
import FooterLogo from "@/img/footer_logo.png"

function App() {
  return (
    <footer id="footer">
      <div className="container-fluid">
        <img src={FooterLogo} />
      </div>
    </footer>
  )
}

export default App
