import React, { useState, useEffect } from "react"
import { useNavigate, Link } from "react-router-dom"
import Button from "react-bootstrap/Button"
import Form from "react-bootstrap/Form"
import { IDepartment } from "@/lib/interface"
import * as api from "@/lib/api"
import { errorHandle } from "@/lib/system"

enum Progress {
  Preparing,
  Attempting,
}

interface Props {
  id?: string
  setErrorMessage: React.Dispatch<React.SetStateAction<string>>
}

function App(props: Props) {
  const [progress, setProgress] = useState(Progress.Preparing)
  const [department, setDepartment] = useState<IDepartment>()

  useEffect(() => {
    if (props.id) {
      getDepartment(props.id)
    }
  }, [])

  const getDepartment = async (id: string) => {
    try {
      const json: any = await api.getDepartmentDb(id)
      console.log(json)
      if (json?.data) {
        setDepartment(json.data.json[0])
      }
    } catch (err) {
      console.log(err)
    }
  }

  const handleNameChange = (e: any) => {
    setDepartment({ ...department, name: e.target.value })
  }

  const handleViewNumberChange = (e: any) => {
    setDepartment({
      ...department,
      view_number: Number(e.target.value),
    })
  }

  const handleStatusChange = () => {
    if (department?.status === 1) {
      setDepartment({ ...department, status: 0 })
    } else {
      setDepartment({ ...department, status: 1 })
    }
  }

  const history = useNavigate()

  const handleSave = async () => {
    setProgress(Progress.Preparing)
    try {
      if (!department?.name) {
        throw new Error("名称は必須です")
      }
      if (!department?.view_number && department?.view_number !== 0) {
        throw new Error("表示順は必須です")
      }
      if (department?.view_number < 1 || department?.view_number > 10000) {
        throw new Error("表示順は1~10000までとなります")
      }
      let json
      //更新
      if (department?.id) {
        json = await api.updateDepartmentDb(department)
        //新規登録
      } else {
        json = await api.setDepartmentDb(department)
      }
      console.log(json)
      history(`/department/`)
    } catch (err) {
      props.setErrorMessage(errorHandle(err))
      console.log(err)
    } finally {
      setProgress(Progress.Preparing)
    }
  }

  return (
    <>
      <div className="row mb-3">
        <div className="col-md-2 col-12 col-auto col-form-label fw-bold">名称</div>
        <div className="col-md-6 col-12">
          <Form className="">
            <Form.Control
              type="text"
              name="name"
              value={department?.name || ""}
              placeholder="名称"
              disabled={progress === Progress.Attempting}
              onChange={handleNameChange}
              maxLength={100}
            />
          </Form>
        </div>
      </div>
      <div className="row mb-3">
        <div className="col-md-2 col-12 fw-bold col-form-label">表示順</div>
        <Form className="col-md-4 col-12">
          <Form.Control
            type="number"
            name="viewNumber"
            value={department?.view_number || ""}
            placeholder="表示順数値"
            disabled={progress === Progress.Attempting}
            onChange={handleViewNumberChange}
            maxLength={5}
          />
        </Form>
      </div>
      <div className="row mb-3">
        <div className="col-md-2 col-12 fw-bold col-form-label">ステータス</div>
        <Form className="col-md-4 col-12">
          <Form.Check
            id="department-status"
            type="switch"
            checked={department?.status === 1}
            onChange={handleStatusChange}
            className="mt-1"
            label={
              department?.status === 1 ? (
                <span className="bg-primary p-1 text-light rounded small">表示中</span>
              ) : (
                <span className="bg-secondary p-1 text-light rounded small">非表示</span>
              )
            }
          />
        </Form>
      </div>
      <div className="col-md-6 col-12 mt-5">
        <Link to={`/department`} className="mx-3 btn btn-secondary btn-block">
          一覧へ戻る
        </Link>
        {department?.id && (
          <>
            <Button
              className="text-right btn-orange d-inline-block px-5 mx-3"
              disabled={progress === Progress.Attempting}
              onClick={handleSave}
            >
              更新
            </Button>
          </>
        )}
        {!department?.id && (
          <Button
            className="text-right btn-orange d-inline-block px-5 mx-3"
            disabled={progress === Progress.Attempting}
            onClick={handleSave}
          >
            登録
          </Button>
        )}
      </div>
    </>
  )
}

export default App
