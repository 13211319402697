import React, { useContext } from "react"
import { Navigate } from "react-router-dom"
import { AuthContext } from "./auth"
import { Header, Sidebar, Footer } from "@/pages/common"

const signInPathName = "/auth/signin"

function App({ Component, ...rest }: any) {
  const auth = useContext(AuthContext)

  let route = <React.Fragment>{Component}</React.Fragment>

  if (rest.contents && !rest.signinFrame) {
    route = (
      <React.Fragment>
        {(rest.wrap || rest.header) && <Header {...rest} />}
        <div className="row m-0">
          <div id="sidebar">
            <Sidebar />
          </div>
          <div id="main">
            <div id="contents">{route}</div>
            {(rest.wrap || rest.footer) && <Footer {...rest} />}
          </div>
        </div>
      </React.Fragment>
    )
  }

  if (rest.id) {
    route = <div id={rest.id}>{route}</div>
  }

  // wrap
  //  route = (<React.Fragment>
  //    {(rest.wrap || rest.header) && <Header {...rest} />}
  //    {route}
  //    {(rest.wrap || rest.footer) && <Footer {...rest} />}
  //  </React.Fragment >)

  // authenticate
  if (rest.auth) {
    if (!auth.isAuthenticated) {
      return (
        <Navigate
          to={{
            pathname: signInPathName,
          }}
        />
      )
    }
  }

  return route
}

export default App
