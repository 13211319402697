import React from "react"
import dayjs from "dayjs"
import "dayjs/locale/ja"
import { IReport, IOperator, IOption, IReportQuestion, IOperatorError } from "@/lib/interface"
import { changeDateString } from "@/lib/system"

dayjs.locale("ja")
const today = new Date()

export const initialReport = {
  id: "",
  job_category: "",
  job_number: "",
  job_date: `${changeDateString(today)}T00:00`,
  weather_id: "",
  temperature: "",
  construction: "",
  //デフォルト値を[非選択]のidとする
  contract_id: "f7617df3-30c4-62b1-acc8-272f47f88112",
  contract_amount: "",
  construction_period_start: changeDateString(today),
  construction_period_end: changeDateString(today),
  progress_rate: "",
  department_id: "",
  office_name: "",
  construction_id: "",
  construction_detail_id: "",
  inspector: "",
  department_manager: "",
  section_manager: "",
  project_manager: "",
  operators: [
    {
      operator_id: "",
      operator_number: "",
    },
  ],
  construction_company: "",
  partners: [""],
  comment: "",
  request_comment: "",
  inspections: new Array<string>(),
}

export type ACTIONTYPE =
  | { type: "INSPECTION_INIT"; list: IOption[] }
  | { type: "QUESTION_INIT"; questions: IReportQuestion[] }
  | { type: "JOB_CATEGORY"; payload: string }
  | { type: "JOB_NUMBER"; payload: string }
  | { type: "JOB_DATE"; payload: string }
  | { type: "WEATHER_ID"; payload: string; list: IOption[] }
  | { type: "TEMPERATURE"; payload: string }
  | { type: "CONSTRUCTION"; payload: string }
  | { type: "CONTRACT_ID"; payload: string; list: IOption[] }
  | { type: "CONTRACT_AMOUNT"; payload: string }
  | { type: "CONSTRUCTION_PERIOD_START"; payload: string }
  | { type: "CONSTRUCTION_PERIOD_END"; payload: string }
  | { type: "PROGRESS_RATE"; payload: string }
  | { type: "DEPARTMENT_ID"; payload: string; list: IOption[] }
  | { type: "OFFICE_NAME"; payload: string }
  | { type: "CONSTRUCTION_ID"; payload: string; list: IOption[] }
  | { type: "CONSTRUCTION_DETAIL_ID"; payload: string; list: IOption[] }
  | { type: "INSPECTOR"; payload: string }
  | { type: "DEPARTMENT_MANAGER"; payload: string }
  | { type: "SECTION_MANAGER"; payload: string }
  | { type: "PROJECT_MANAGER"; payload: string }
  | { type: "OPERATOR_ID"; payload: string; index: number; list: IOption[] }
  | { type: "OPERATOR_NUMBER"; payload: string; index: number }
  | { type: "OPERATOR_ADD" }
  | { type: "OPERATOR_DEL"; index: number }
  | { type: "CONSTRUCTION_COMPANY"; payload: string }
  | { type: "PARTNER"; payload: string; index: number }
  | { type: "PARTNER_ADD" }
  | { type: "PARTNER_DEL"; index: number }
  | { type: "COMMENT"; payload: string }
  | { type: "REQUEST_COMMENT"; payload: string }
  | { type: "INSPECTION_ID"; payload: string; list: IOption[] }
  | {
      type: "RESULT_ID"
      result_id: string
      question_id: string
    }
  | { type: "QUESTION_COMMENT"; payload: string; question_id: string }
  | { type: "QUESTION_IMAGE"; payload: File[]; question_id: string }
  | { type: "DEL_PREVIEW_QUESTION_IMAGE"; question_id: string }
  | { type: "DEL_QUESTION_IMAGE"; question_id: string }
  | { type: "VALID_JOB_CATEGORY"; payload: string }
  | { type: "VALID_JOB_NUMBER"; payload: string }
  | { type: "VALID_JOB_DATE"; payload: string }
  | { type: "VALID_WEATHER_ID"; payload: string }
  | { type: "VALID_TEMPERATURE"; payload: string }
  | { type: "VALID_CONSTRUCTION"; payload: string }
  | { type: "VALID_CONTRACT_ID"; payload: string }
  | { type: "VALID_CONTRACT_AMOUNT"; payload: string }
  | { type: "VALID_CONSTRUCTION_PERIOD_START"; payload: string }
  | { type: "VALID_CONSTRUCTION_PERIOD_END"; payload: string }
  | { type: "VALID_PROGRESS_RATE"; payload: string }
  | { type: "VALID_DEPARTMENT_ID"; payload: string }
  | { type: "VALID_OFFICE_NAME"; payload: string }
  | { type: "VALID_CONSTRUCTION_ID"; payload: string; list: IOption[] }
  | { type: "VALID_CONSTRUCTION_DETAIL_ID"; payload: string; list: IOption[] }
  | { type: "VALID_INSPECTOR"; payload: string }
  | { type: "VALID_DEPARTMENT_MANAGER"; payload: string }
  | { type: "VALID_SECTION_MANAGER"; payload: string }
  | { type: "VALID_PROJECT_MANAGER"; payload: string }
  | { type: "VALID_OPERATOR_ID"; payload: string; index: number }
  | { type: "VALID_OPERATOR_NUMBER"; payload: string; index: number }
  | { type: "VALID_OPERATOR_ADD" }
  | { type: "VALID_OPERATOR_DEL"; index: number }
  | { type: "VALID_CONSTRUCTION_COMPANY"; payload: string }
  | { type: "VALID_PARTNER"; payload: string; index: number }
  | { type: "VALID_PARTNER_ADD" }
  | { type: "VALID_PARTNER_DEL"; index: number }
  | { type: "VALID_COMMENT"; payload: string }
  | { type: "VALID_REQUEST_COMMENT"; payload: string }
  | { type: "VALID_INSPECTION_ID"; payload: string }
  | { type: "REPORT_ID"; payload: string }
  | { type: "SET_REPORT"; payload: IReport }

export const reducerFunc: React.Reducer<IReport, ACTIONTYPE> = (report: IReport, action: ACTIONTYPE): IReport => {
  let errorMessage = ""
  const numPattern = /^[0-9]+$/
  switch (action.type) {
    // 点検分類の初期値を設定
    case "INSPECTION_INIT":
      if (report.inspections && report.inspections.length > 0) {
        return report
      } else {
        //点検分類全てにチェックを入れる
        const setInspections = action.list?.map((inspectionId: IOption) => {
          return inspectionId.id || ""
        })
        return { ...report, inspections: setInspections }
      }
    // チェックリストの初期値セット 作業分類と部門によって変化する
    case "QUESTION_INIT": {
      //この中に作業分類に適合するチェックリストを全体チェックリストから格納する
      let addQuestions = new Array<IReportQuestion>()
      if (report.inspections) {
        const questions = action.questions.filter((question: IReportQuestion) => {
          return report.inspections && question.inspection_id && report.inspections.includes(question.inspection_id)
        })
        addQuestions = [...addQuestions, ...questions]
      }
      //この中に部門に適合するチェックリストを全体チェックリストから格納する
      if (report.department_id) {
        const questions = action.questions.filter((question: IReportQuestion) => {
          return report.department_id === question.department_id
        })
        addQuestions = [...addQuestions, ...questions]
      }
      addQuestions = addQuestions.map((question: IReportQuestion) => {
        //回答済みチェックリストの場合は属性値を置き換える
        const answedQuestion = report.questions?.find((repliedQuestion: IReportQuestion) => {
          return question.id === repliedQuestion.id
        })
        if (answedQuestion) {
          return answedQuestion
          // 回答済みチェックリストでない場合にはresult_id: "RESULT_SLASH"にして返却
        } else {
          return { ...question, result_id: "RESULT_SLASH" }
        }
      })
      return { ...report, questions: addQuestions }
    }
    // JOB番号が変更された時 JOB番号2桁の入力欄
    case "JOB_CATEGORY":
      return { ...report, job_category: action.payload }
    // JOB番号が変更された時 JOB番号11桁の入力欄
    case "JOB_NUMBER":
      return { ...report, job_number: action.payload }
    // 年月日が変更された時
    case "JOB_DATE":
      return { ...report, job_date: action.payload }
    // 天候が変更された時
    case "WEATHER_ID":
      return { ...report, weather_id: action.payload }
    // 気温が変更された時
    case "TEMPERATURE":
      return { ...report, temperature: action.payload }
    // 工事名が変更された時
    case "CONSTRUCTION":
      return { ...report, construction: action.payload }
    // 請負区分が変更された時
    case "CONTRACT_ID": {
      const contracts = action.list.map((contract: IOption) => {
        return contract.id
      })
      if (contracts.includes(action.payload)) {
        return { ...report, contract_id: action.payload }
      } else {
        //選択肢以外の値が入れられた場合には更新しない
        return report
      }
    }
    // 請負金額が変更された時
    case "CONTRACT_AMOUNT":
      return { ...report, contract_amount: action.payload }
    // 工期が変更された時
    case "CONSTRUCTION_PERIOD_START":
      return { ...report, construction_period_start: action.payload }
    case "CONSTRUCTION_PERIOD_END":
      return { ...report, construction_period_end: action.payload }
    //進捗率が変更された時
    case "PROGRESS_RATE":
      return { ...report, progress_rate: action.payload }
    // 部門が変更された時
    case "DEPARTMENT_ID":
      return { ...report, department_id: action.payload }
    // 部署が変更された時
    case "OFFICE_NAME":
      return { ...report, office_name: action.payload }
    // 工事種類が変更された時
    case "CONSTRUCTION_ID":
      return { ...report, construction_id: action.payload }
    // 工事種類その2が変更された時
    case "CONSTRUCTION_DETAIL_ID":
      return { ...report, construction_detail_id: action.payload }
    // 点検者が変更された時
    case "INSPECTOR":
      return { ...report, inspector: action.payload }
    // 部長が変更された時
    case "DEPARTMENT_MANAGER":
      return { ...report, department_manager: action.payload }
    // 課長が変更された時
    case "SECTION_MANAGER":
      return { ...report, section_manager: action.payload }
    // が変更された時
    case "PROJECT_MANAGER":
      return { ...report, project_manager: action.payload }
    // 被点検者が変更された時
    case "OPERATOR_ID": {
      const updateIdOperator = {
        operator_id: action.payload,
        operator_number: report.operators?.[action.index].operator_number,
      }
      const updateIdOperators = report.operators?.map((operator: IOperator, index: number) => {
        return index === action.index ? updateIdOperator : operator
      })
      return { ...report, operators: updateIdOperators }
    }
    // 当日の作業員人数が変更された時
    case "OPERATOR_NUMBER": {
      const updateNumberOperator = {
        operator_id: report.operators?.[action.index].operator_id,
        operator_number: action.payload,
      }
      const updateNumberOperators = report.operators?.map((operator: IOperator, index: number) => {
        return index === action.index ? updateNumberOperator : operator
      })
      return { ...report, operators: updateNumberOperators }
    }
    // 当日の作業員人数が追加された時  エラー文言用のオブジェクトerrorsも同時に追加
    case "OPERATOR_ADD":
      if (report.operators && report.operators.length >= 12) {
        return report
      }
      return {
        ...report,
        operators: [
          ...(report.operators || []),
          {
            operator_id: "",
            operator_number: "",
          },
        ],
        errors: {
          ...report.errors,
          operators: [
            ...(report.errors?.operators || []),
            {
              idMessage: "",
              numMessage: "",
            },
          ],
        },
      }
    // 当日の作業員人数が削除された時 エラー文言用のオブジェクトも同時に削除
    case "OPERATOR_DEL":
      if (report.operators && report.operators.length <= 1) {
        return report
      }
      return {
        ...report,
        operators: [
          ...(report.operators?.filter((_operator: IOperator, index: number) => {
            return index !== action.index
          }) || []),
        ],
        errors: {
          ...report.errors,
          operators: [
            ...(report.errors?.operators?.filter((_operator: IOperatorError, index: number) => {
              return index !== action.index
            }) || []),
          ],
        },
      }
    // 建築会社（統括安全衛生責任者）が変更された時
    case "CONSTRUCTION_COMPANY":
      return { ...report, construction_company: action.payload }
    // 当日入場協力会社が変更された時
    case "PARTNER": {
      const updatePartners = report.partners?.map((partner: string, index: number) => {
        return index === action.index ? action.payload : partner
      })
      return { ...report, partners: updatePartners }
    }
    // 当日入場協力会社が追加された時 エラー文言用のオブジェクトも同時に追加
    case "PARTNER_ADD":
      if (report.partners && report.partners.length >= 10) {
        return report
      }
      return {
        ...report,
        partners: [...(report.partners || []), ""],
        errors: {
          ...report.errors,
          partners: [...(report.errors?.partners || []), ""],
        },
      }
    // 当日入場協力会社が削除された時 エラー文言用のオブジェクトも同時に削除
    case "PARTNER_DEL":
      if (report.partners && report.partners.length <= 1) {
        return report
      }
      return {
        ...report,
        partners: [
          ...(report.partners?.filter((_partner: string, index: number) => {
            return index !== action.index
          }) || []),
        ],
        errors: {
          ...report.errors,
          partners: [
            ...(report.errors?.partners?.filter((_partner: string, index: number) => {
              return index !== action.index
            }) || []),
          ],
        },
      }
    // 総評が変更された時
    case "COMMENT":
      return { ...report, comment: action.payload }
    // 現場からの要望事項が変更された時
    case "REQUEST_COMMENT":
      return { ...report, request_comment: action.payload }
    // 点検分類が変更された時
    case "INSPECTION_ID": {
      let updateInspections = []
      let updateQuestions = []
      // desabledにされた場合
      if (
        report.inspections &&
        report.questions &&
        report.inspections.find((inspectionId: string) => {
          return inspectionId === action.payload
        })
      ) {
        updateInspections = report.inspections.filter((inspectionId: string) => {
          return inspectionId !== action.payload
        })
        updateQuestions = report.questions.filter((question: IReportQuestion) => {
          return question.inspection_id !== action.payload
        })
        return {
          ...report,
          inspections: updateInspections,
          questions: updateQuestions,
        }
        // enabledにされた場合
      } else {
        return {
          ...report,
          inspections: [...(report.inspections || []), action.payload],
        }
      }
    }
    // チェックリストが変更された時
    case "RESULT_ID": {
      const resultIds = ["RESULT_SLASH", "RESULT_CIRCLE", "RESULT_TRIANGLE", "RESULT_CROSS"]
      if (resultIds.includes(action.result_id)) {
        const updateQuestions = report.questions?.map((reportQuestion: IReportQuestion) => {
          if (reportQuestion.id == action.question_id) {
            ///か○の場合コメントを消す
            if (["RESULT_SLASH", "RESULT_CIRCLE"].includes(action.result_id)) {
              return { ...reportQuestion, result_id: action.result_id, comment: "" }
            }
            return { ...reportQuestion, result_id: action.result_id }
          } else {
            return reportQuestion
          }
        })
        return { ...report, questions: updateQuestions }
      } else {
        //選択肢以外の値が入れられた場合には更新しない
        return report
      }
    }
    //チェックリストごとのコメント(是正内容)が変更されたとき
    case "QUESTION_COMMENT": {
      const updateQuestionComments = report.questions?.map((reportQuestion: IReportQuestion) => {
        if (reportQuestion.id == action.question_id) {
          return { ...reportQuestion, comment: action.payload }
        } else {
          return reportQuestion
        }
      })
      return { ...report, questions: updateQuestionComments }
    }
    //チェックリストごとの画像が変更されたとき
    case "QUESTION_IMAGE": {
      //画像処理
      const MAX_THUMBNAIL_SIZE = 5242880
      const MIN_THUMBNAIL_SIZE = 0
      const acceptedFiles = action.payload
      if (acceptedFiles[0].type.includes("image/")) {
        if (acceptedFiles[0].size > MIN_THUMBNAIL_SIZE && acceptedFiles[0].size <= MAX_THUMBNAIL_SIZE) {
          try {
            //これをreport.questionsごとにセットする
            const updateQuestionImages = report.questions?.map((reportQuestion: IReportQuestion) => {
              if (reportQuestion.id == action.question_id) {
                return { ...reportQuestion, image_file: acceptedFiles[0] }
              } else {
                return reportQuestion
              }
            })
            return { ...report, questions: updateQuestionImages }
          } catch (err) {
            console.log(err)
          }
        } else {
          alert("ファイルサイズは5Mまでです")
        }
      } else {
        alert("ファイル形式が不正です")
      }
      return report
    }
    //チェックリストの仮画像を削除
    case "DEL_PREVIEW_QUESTION_IMAGE": {
      const updateQuestions = report.questions?.map((reportQuestion: IReportQuestion) => {
        if (reportQuestion.id == action.question_id) {
          return { ...reportQuestion, image_file: null }
        } else {
          return reportQuestion
        }
      })
      if (updateQuestions) {
        return { ...report, questions: updateQuestions }
      } else {
        return report
      }
    }
    //チェックリストの画像を削除
    case "DEL_QUESTION_IMAGE": {
      const updateQuestions = report.questions?.map((reportQuestion: IReportQuestion) => {
        if (reportQuestion.id == action.question_id) {
          return { ...reportQuestion, image: "" }
        } else {
          return reportQuestion
        }
      })
      if (updateQuestions) {
        return { ...report, questions: updateQuestions }
      } else {
        return report
      }
    }
    // JOB番号エラー処理 2桁
    case "VALID_JOB_CATEGORY":
      if (action.payload) {
        if (action.payload.length > 2) {
          errorMessage = "2文字以内で入力してください"
        } else if (!numPattern.test(action.payload)) {
          errorMessage = "半角数字で入力してください"
        }
      }
      return {
        ...report,
        errors: {
          ...report.errors,
          job_category: errorMessage,
        },
      }
    // JOB番号エラー処理 11桁
    case "VALID_JOB_NUMBER":
      if (action.payload) {
        if (action.payload.length > 11) {
          errorMessage = "11文字以内で入力してください"
        } else if (!numPattern.test(action.payload)) {
          errorMessage = "半角数字で入力してください"
        }
      }
      return {
        ...report,
        errors: {
          ...report.errors,
          job_number: errorMessage,
        },
      }
    // 年月日エラー処理
    case "VALID_JOB_DATE":
      if (action.payload.length == 0) {
        console.log("blank")
      } else if (!dayjs(action.payload).isValid()) {
        errorMessage = "日付が不正です"
      }
      return {
        ...report,
        errors: {
          ...report.errors,
          job_date: errorMessage,
        },
      }
    // 天候エラー処理
    case "VALID_WEATHER_ID":
      if (action.payload.length == 0) {
        console.log("blank")
      }
      return {
        ...report,
        errors: {
          ...report.errors,
          weather_id: errorMessage,
        },
      }
    // 気温エラー処理
    case "VALID_TEMPERATURE":
      if (action.payload.length == 0) {
        console.log("blank")
      } else if (action.payload.length > 3) {
        errorMessage = "3文字以内で入力してください"
      } else if (!numPattern.test(action.payload)) {
        errorMessage = "半角数字で入力してください"
      }
      return {
        ...report,
        errors: {
          ...report.errors,
          temperature: errorMessage,
        },
      }
    // 工事名エラー処理
    case "VALID_CONSTRUCTION":
      if (action.payload.length == 0) {
        console.log("blank")
      } else if (action.payload.length > 40) {
        errorMessage = "40文字以内で入力してください"
      }
      return {
        ...report,
        errors: {
          ...report.errors,
          construction: errorMessage,
        },
      }
    // 請負区分エラー処理
    case "VALID_CONTRACT_ID":
      if (action.payload.length == 0) {
        console.log("blank")
      }
      return {
        ...report,
        errors: {
          ...report.errors,
          contract_id: errorMessage,
        },
      }
    // 請負金額エラー処理
    case "VALID_CONTRACT_AMOUNT":
      if (action.payload.length == 0) {
        console.log("blank")
      } else if (action.payload.length > 11) {
        errorMessage = "3文字以内で入力してください"
      } else if (!numPattern.test(action.payload)) {
        errorMessage = "半角数字で入力してください"
      }
      return {
        ...report,
        errors: {
          ...report.errors,
          contract_amount: errorMessage,
        },
      }
    // 工期エラー処理
    case "VALID_CONSTRUCTION_PERIOD_START":
      if (action.payload.length == 0) {
        console.log("blank")
      } else if (!dayjs(action.payload).isValid()) {
        errorMessage = "日付が不正です"
      }
      return {
        ...report,
        errors: {
          ...report.errors,
          construction_period_start: errorMessage,
        },
      }
    case "VALID_CONSTRUCTION_PERIOD_END":
      if (action.payload.length == 0) {
        console.log("blank")
      } else if (!dayjs(action.payload).isValid()) {
        errorMessage = "日付が不正です"
      }
      return {
        ...report,
        errors: {
          ...report.errors,
          construction_period_end: errorMessage,
        },
      }
    //進捗率
    case "VALID_PROGRESS_RATE":
      if (action.payload.length == 0) {
        console.log("blank")
      } else if (Number(action.payload) <= 0 || Number(action.payload) >= 100) {
        errorMessage = "進捗率が不正です"
      }
      return {
        ...report,
        errors: {
          ...report.errors,
          progress_rate: errorMessage,
        },
      }
    // 部門エラー処理
    // case "VALID_DEPARTMENT_ID":
    //   if (action.payload.length == 0) {
    //     errorMessage = "部門は選択必須です"
    //   }
    //   return {
    //     ...report,
    //     errors: {
    //       ...report.errors,
    //       department_id: errorMessage,
    //     },
    //   }
    // 部署エラー処理
    case "VALID_OFFICE_NAME":
      if (action.payload.length == 0) {
        console.log("blank")
      } else if (action.payload.length > 15) {
        errorMessage = "15文字以内で入力してください"
      }
      return {
        ...report,
        errors: {
          ...report.errors,
          office_name: errorMessage,
        },
      }
    // 工事種類エラー処理
    case "VALID_CONSTRUCTION_ID":
      if (action.payload.length == 0) {
        console.log("blank")
      }
      return {
        ...report,
        errors: {
          ...report.errors,
          construction_id: errorMessage,
        },
      }
    // 工事種類その2エラー処理
    case "VALID_CONSTRUCTION_DETAIL_ID":
      if (action.payload.length == 0) {
        console.log("blank")
      }
      return {
        ...report,
        errors: {
          ...report.errors,
          construction_detail_id: errorMessage,
        },
      }
    // 点検者エラー処理
    case "VALID_INSPECTOR":
      if (action.payload.length == 0) {
        console.log("blank")
      } else if (action.payload.length > 10) {
        errorMessage = "10文字以内で入力してください"
      }
      return {
        ...report,
        errors: {
          ...report.errors,
          inspector: errorMessage,
        },
      }
    // 部長エラー処理
    case "VALID_DEPARTMENT_MANAGER":
      if (action.payload.length == 0) {
        console.log("blank")
      } else if (action.payload.length > 10) {
        errorMessage = "10文字以内で入力してください"
      }
      return {
        ...report,
        errors: {
          ...report.errors,
          department_manager: errorMessage,
        },
      }
    // 課長エラー処理
    case "VALID_SECTION_MANAGER":
      if (action.payload.length == 0) {
        console.log("blank")
      } else if (action.payload.length > 10) {
        errorMessage = "10文字以内で入力してください"
      }
      return {
        ...report,
        errors: {
          ...report.errors,
          section_manager: errorMessage,
        },
      }
    // 被点検者エラー処理
    case "VALID_PROJECT_MANAGER":
      if (action.payload.length == 0) {
        console.log("blank")
      } else if (action.payload.length > 10) {
        errorMessage = "10文字以内で入力してください"
      }
      return {
        ...report,
        errors: {
          ...report.errors,
          project_manager: errorMessage,
        },
      }
    // 当日の作業員種別エラー処理
    case "VALID_OPERATOR_ID": {
      if (action.payload.length == 0) {
        console.log("blank")
      }
      if (report.errors?.operators) {
        console.log(errorMessage)
        const updateErrorsOperators = report.errors.operators.slice()
        updateErrorsOperators[action.index] = {
          idMessage: errorMessage,
          numMessage: updateErrorsOperators[action.index]?.numMessage,
        }
        return {
          ...report,
          errors: {
            ...report.errors,
            operators: updateErrorsOperators,
          },
        }
      } else {
        return report
      }
    }
    // 当日の作業員人数エラー処理
    case "VALID_OPERATOR_NUMBER": {
      if (action.payload.length == 0) {
        console.log("blank")
      } else if (action.payload.length > 10) {
        errorMessage = "10文字以内で入力してください"
      } else if (!numPattern.test(action.payload)) {
        errorMessage = "半角数字で入力してください"
      }
      if (report.errors?.operators) {
        const updateErrorsOperators = report.errors.operators.slice()
        updateErrorsOperators[action.index] = {
          idMessage: updateErrorsOperators[action.index]?.idMessage,
          numMessage: errorMessage,
        }
        return {
          ...report,
          errors: {
            ...report.errors,
            operators: updateErrorsOperators,
          },
        }
      } else {
        return report
      }
    }
    // 建築会社（統括安全衛生責任者）エラー処理
    case "VALID_CONSTRUCTION_COMPANY":
      if (action.payload.length == 0) {
        console.log("blank")
      } else if (action.payload.length > 15) {
        errorMessage = "15文字以内で入力してください"
      }
      return {
        ...report,
        errors: {
          ...report.errors,
          construction_company: errorMessage,
        },
      }
    // 当日入場協力会社エラー処理
    case "VALID_PARTNER":
      if (action.payload.length == 0) {
        console.log("blank")
      } else if (action.payload.length > 30) {
        errorMessage = "30文字以内で入力してください"
      }
      if (report.errors?.operators && report.errors?.partners) {
        const updateErrorsPartners = report.errors.partners.slice()
        updateErrorsPartners[action.index] = errorMessage
        return {
          ...report,
          errors: {
            ...report.errors,
            partners: updateErrorsPartners,
          },
        }
      } else {
        return report
      }
    // 総評エラー処理
    case "VALID_COMMENT":
      if (action.payload.length == 0) {
        console.log("blank")
      } else if (action.payload.length > 2000) {
        errorMessage = "2000文字以内で入力してください"
      }
      return {
        ...report,
        errors: {
          ...report.errors,
          comment: errorMessage,
        },
      }
    // 現場からの要望事項エラー処理
    case "VALID_REQUEST_COMMENT":
      if (action.payload.length == 0) {
        console.log("blank")
      } else if (action.payload.length > 2000) {
        errorMessage = "2000文字以内で入力してください"
      }
      return {
        ...report,
        errors: {
          ...report.errors,
          request_comment: errorMessage,
        },
      }
    // 点検分類エラー処理
    case "VALID_INSPECTION_ID":
      if (report.inspections && report.inspections.length == 0) {
        errorMessage = "点検分類を選択してください"
      }
      return {
        ...report,
        errors: {
          ...report.errors,
          inspections: errorMessage,
        },
      }
    // 新規登録にレポートIDをセット 次保存時には更新とするため
    case "REPORT_ID":
      if (action.payload) {
        return {
          ...report,
          id: action.payload,
        }
      } else {
        return report
      }
    // reportデータをセット、ない場合にはデフォルトの値
    case "SET_REPORT":
      if (action.payload) {
        return action.payload
      } else {
        return initialReport
      }
    default:
      return report
  }
}

export const errorCounter = (report: IReport): number => {
  let errorCount = 0
  //単数系<string>のエラーの数をインクリメント
  if (report.errors) {
    Object.values(report.errors)?.forEach((value: string | IOperatorError[] | string[]) => {
      //typeでエラー内容を判定する string or string[] or IOperatorError[]
      if (typeof value === "string" && value !== "") {
        errorCount++
      }
    })
  }
  //operators<IOperatorError[]>のエラーの数をインクリメント
  report.errors?.operators?.forEach((operatorError: IOperatorError) => {
    if (operatorError.idMessage !== "") {
      errorCount++
    }
    if (operatorError.numMessage !== "") {
      errorCount++
    }
  })
  // partners<string[]>のエラーの数をインクリメント
  report.errors?.partners?.forEach((partnerError: string) => {
    if (partnerError !== "") {
      errorCount++
    }
  })
  return errorCount
}
