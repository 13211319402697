import React, { useEffect, useRef, useState } from "react"
import { Link, useNavigate } from "react-router-dom"
import Button from "react-bootstrap/Button"
import Overlay from "react-bootstrap/Overlay"
import Tooltip from "react-bootstrap/Tooltip"
import Card from "react-bootstrap/Card"
import Form from "react-bootstrap/Form"
import { IconCircleNumber1 } from "@tabler/icons-react"
import { IconCircleNumber2 } from "@tabler/icons-react"
import { IconCircleNumber3 } from "@tabler/icons-react"
import { IconCircleNumber4 } from "@tabler/icons-react"
import { IconCircleNumber5 } from "@tabler/icons-react"
import { IconCircleNumber6 } from "@tabler/icons-react"
import { IconAlertTriangle } from "@tabler/icons-react"
import { IInspection, IDepartment, IQuestion } from "@/lib/interface"
import * as api from "@/lib/api"
import { errorHandle } from "@/lib/system"

enum Progress {
  Preparing,
  Attempting,
}

interface Props {
  id?: string
  setErrorMessage: React.Dispatch<React.SetStateAction<string>>
}

function App(props: Props) {
  const [progress, setProgress] = useState(Progress.Preparing)
  const [inspections, setInspections] = useState<IInspection[] | null>(null)
  const [departments, setDepartments] = useState<IDepartment[] | null>(null)
  const [question, setQuestion] = useState<IQuestion>()

  useEffect(() => {
    if (props.id) {
      getQuestion(props.id)
    }
    getSelect()
  }, [])

  const getQuestion = async (id: string) => {
    try {
      const json: any = await api.getQuestionDb(id)
      console.log(json)
      if (json?.data) {
        setQuestion(json.data.json[0])
      }
    } catch (err) {
      console.log(err)
    }
  }

  const getSelect = async () => {
    try {
      const inspectionJson: any = await api.getInspectionsDb()
      console.log(inspectionJson)
      if (inspectionJson?.data) {
        setInspections(inspectionJson?.data.json)
      }
      const departmentJson = await api.getDepartmentsDb("", "")
      console.log(departmentJson)
      if (departmentJson?.data) {
        setDepartments(departmentJson?.data.json)
      }
    } catch (err) {
      console.log(err)
    }
  }

  const handleNameChange = (e: any) => {
    setQuestion({ ...question, name: e.target.value })
    if (guideCount === 2) {
      incrementGuideCount()
    }
  }

  const handleClassNameChange = (e: any) => {
    setQuestion({ ...question, class_name: e.target.value })
    if (guideCount === 1) {
      incrementGuideCount()
    }
  }

  const handleViewNumberChange = (e: any) => {
    setQuestion({
      ...question,
      view_number: Number(e.target.value),
    })
    if (guideCount === 3) {
      incrementGuideCount()
    }
  }

  const handleStatusChange = () => {
    if (question?.status === 1) {
      setQuestion({ ...question, status: 0 })
    } else {
      setQuestion({ ...question, status: 1 })
    }
    if (guideCount === 4) {
      incrementGuideCount()
    }
  }

  const handleInspectionsChange = (e: any) => {
    setQuestion({
      ...question,
      inspection_id: e.target.value,
      department_id: "",
    })
  }

  const handleDepartmentChange = (e: any) => {
    setQuestion({
      ...question,
      inspection_id: "",
      department_id: e.target.value,
    })
    if (guideCount === 0) {
      incrementGuideCount()
    }
  }

  const history = useNavigate()

  const handleSave = async () => {
    setProgress(Progress.Preparing)
    try {
      if (!question?.inspection_id && !question?.department_id) {
        throw new Error("点検分類か部門は必須です")
      }
      if (!question?.name) {
        throw new Error("チェック内容は必須です")
      }
      if (!question?.view_number && question?.view_number !== 0) {
        throw new Error("表示順は必須です")
      }
      if (question?.view_number < 1 || question?.view_number > 10000) {
        throw new Error("表示順は1~10000までとなります")
      }
      if (question?.name.length > 150) {
        throw new Error("チェック内容は150文字以内で入力してください")
      }
      let json
      //更新
      if (question?.id) {
        json = await api.updateQuestionDb(question)
        //新規登録
      } else {
        json = await api.setQuestionDb(question)
      }
      console.log(json)
      history(`/question/`)
    } catch (err) {
      props.setErrorMessage(errorHandle(err))
      console.log(err)
    } finally {
      setProgress(Progress.Preparing)
    }
  }

  const guide1 = useRef(null)
  const guide2 = useRef(null)
  const guide3 = useRef(null)
  const guide4 = useRef(null)
  const guide5 = useRef(null)
  const guide6 = useRef(null)

  const [guideCount, setGuideCount] = useState(0)

  const showGuide1 = guideCount === 0
  const showGuide2 = guideCount === 1
  const showGuide3 = guideCount === 2
  const showGuide4 = guideCount === 3
  const showGuide5 = guideCount === 4
  const showGuide6 = guideCount === 5

  const incrementGuideCount = () => {
    if (guideCount < 5) {
      setGuideCount(guideCount + 1)
    }
  }

  useEffect(() => {
    if (question?.id) {
      setGuideCount(6)
    }
  }, [question])

  return (
    <>
      <div className="row mb-3">
        <div className="col-xl-2 col-12 col-auto col-form-label fw-bold bg-info bg-opacity-25">点検分類</div>
        <Card className="col-md-8 col-12 ms-1" style={{ backgroundColor: "#f8d7da" }}>
          <Card.Body>
            <Card.Title style={{ color: "#842029" }}>
              <IconAlertTriangle size={"1.25rem"} className="pb-1" />
              安全衛生推進室以外選択しないでください。
            </Card.Title>
            <Form className="row">
              {inspections?.map((inspection: IInspection, index: number) => {
                return (
                  <div key={`inspection-${index}`} className="col-xl-4 col-sm-5 g-1">
                    <div className="bg-light m-1 p-1 rounded">
                      <Form.Check
                        inline
                        type="radio"
                        id={`inspection-${index}`}
                        name="inspection"
                        label={inspection.name}
                        value={inspection.id}
                        checked={question?.inspection_id === inspection.id}
                        onChange={handleInspectionsChange}
                      />
                    </div>
                  </div>
                )
              })}
            </Form>
          </Card.Body>
        </Card>
      </div>
      <div className="row mb-3">
        <div className="col-xl-2 col-12 col-auto col-form-label fw-bold bg-warning bg-opacity-25" ref={guide1}>
          部門独自項目
          <Overlay target={guide1.current} show={showGuide1}>
            <Tooltip>
              <IconCircleNumber1 size={"1.2rem"} className="pb-1" />
              点検分類を選択してください。
            </Tooltip>
          </Overlay>
        </div>
        <div className="col-md-8 col-12">
          <Form className="row">
            {departments?.map((department: IDepartment, index: number) => {
              return (
                <div key={`department-${index}`} className="col-xl-4 col-sm-5 g-1">
                  <div className="bg-light m-1 p-1 rounded">
                    <Form.Check
                      inline
                      type="radio"
                      id={`department-${index}`}
                      name="department"
                      label={department.name}
                      value={department.id}
                      checked={question?.department_id === department.id}
                      onChange={handleDepartmentChange}
                    />
                  </div>
                </div>
              )
            })}
          </Form>
        </div>
      </div>
      <div className="row mb-3">
        <div className="col-md-2 col-12 col-auto col-form-label fw-bold">大項目</div>
        <div className="col-md-6 col-12">
          <Form className="" ref={guide2}>
            <Form.Control
              type="text"
              name="class_name"
              value={question?.class_name || ""}
              placeholder="大項目"
              disabled={progress === Progress.Attempting}
              onChange={handleClassNameChange}
              maxLength={100}
            />
          </Form>
          <Overlay target={guide2.current} show={showGuide2} placement="right">
            <Tooltip>
              <IconCircleNumber2 size={"1.2rem"} className="pb-1" />
              独自項目名を入力してください。
              <br />
              例) 安全書類、電気、有機溶剤等
            </Tooltip>
          </Overlay>
        </div>
      </div>
      <div className="row mb-3">
        <div className="col-md-2 col-12 col-auto col-form-label fw-bold">チェック内容</div>
        <div className="col-md-6 col-12">
          <Form className="" ref={guide3}>
            <Form.Control
              type="text"
              name="name"
              value={question?.name || ""}
              placeholder="チェック内容"
              disabled={progress === Progress.Attempting}
              onChange={handleNameChange}
              maxLength={150}
            />
          </Form>
          <Overlay target={guide3.current} show={showGuide3} placement="right">
            <Tooltip>
              <IconCircleNumber3 size={"1.2rem"} className="pb-1" />
              追加したいチェック内容を入力してください。
              <br />
              例) 作業場の照度は適正か
            </Tooltip>
          </Overlay>
        </div>
      </div>
      <div className="row mb-3">
        <div className="col-md-2 col-12 col-auto col-form-label fw-bold">表示順</div>
        <Form className="col-md-6 col-12" ref={guide4}>
          <Form.Control
            type="number"
            name="viewNumber"
            value={question?.view_number || ""}
            placeholder="表示順数値"
            disabled={progress === Progress.Attempting}
            onChange={handleViewNumberChange}
            maxLength={20}
            min={1}
          />
        </Form>
        <Overlay target={guide4.current} show={showGuide4} placement="right">
          <Tooltip>
            <IconCircleNumber4 size={"1.2rem"} className="pb-1" />
            表示順を入力してください。
            <br />
            各部門でこれまで作成した独自項目の標準を確認の上、
            <br />
            それ以降の順番を入力してください。
          </Tooltip>
        </Overlay>
      </div>
      <div className="row mb-3">
        <div className="col-md-2 col-12 fw-bold col-form-label">ステータス</div>
        <Form className="col-md-4 col-12" ref={guide5}>
          <label htmlFor="department-status">
            <Form.Check
              id="department-status"
              type="switch"
              checked={question?.status === 1}
              onChange={handleStatusChange}
              className="mt-1"
              label={
                question?.status === 1 ? (
                  <span className="bg-primary p-1 text-light rounded small">表示中</span>
                ) : (
                  <span className="bg-secondary p-1 text-light rounded small">非表示</span>
                )
              }
            />
          </label>
        </Form>
        <Overlay target={guide5.current} show={showGuide5} placement="right">
          <Tooltip>
            <IconCircleNumber5 size={"1.2rem"} className="pb-1" />
            ステータスを表示にしてください。
          </Tooltip>
        </Overlay>
      </div>
      <div className="col-md-6 col-12 mt-5">
        <Link to={`/question`} className="mx-3 btn btn-secondary btn-block">
          一覧へ戻る
        </Link>
        {question?.id && (
          <>
            <Button
              className="text-right btn-orange d-inline-block px-5 mx-3"
              disabled={progress === Progress.Attempting}
              onClick={handleSave}
            >
              更新
            </Button>
          </>
        )}
        {!question?.id && (
          <>
            <Button
              className="text-right btn-orange d-inline-block px-5 mx-3"
              disabled={progress === Progress.Attempting}
              onClick={handleSave}
              ref={guide6}
            >
              登録
            </Button>
            <Overlay target={guide6.current} show={showGuide6} placement="right">
              <Tooltip>
                <IconCircleNumber6 size={"1.2rem"} className="pb-1" />
                登録ボタンを押してください。
              </Tooltip>
            </Overlay>
          </>
        )}
      </div>
    </>
  )
}

export default App
