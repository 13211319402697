import React, { useCallback, useContext, useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"
import Button from "react-bootstrap/Button"
import Form from "react-bootstrap/Form"
import { AuthContext } from "@/lib/auth"
import { errorHandle } from "@/lib/system"
import Logo from "@/img/logo.png"
import { Modal } from "../common"

enum Progress {
  SignIn,
  Confirm,
}

function App() {
  const [progress, setProgress] = useState(Progress.SignIn)
  const [errorMessage, setErrorMessage] = useState("")
  const [modalShow, setModalShow] = useState(false)
  const [attempting, setAttempting] = useState(false)

  const [username, setUsername] = useState("")
  const [confirmCode, setConfirmCode] = useState("")
  const [user, setUser] = useState<any>(null)

  const history = useNavigate()

  const auth = useContext(AuthContext)

  // handler
  const handleUsernameChange = useCallback((e: any) => setUsername(e.target.value), [])
  const handleConfirmCodeChange = useCallback((e: any) => setConfirmCode(e.target.value), [])

  const handleSignIn = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    setAttempting(true)
    try {
      const json = await auth.signIn(username)
      if (json) {
        setAttempting(false)
        setProgress(Progress.Confirm)
        setUser(json)
      }
    } catch (err) {
      setErrorMessage(errorHandle(err))
    }
    setAttempting(false)
  }

  const handleConfirm = useCallback(
    (event: React.FormEvent<HTMLFormElement>) => {
      event.preventDefault()
      setAttempting(true)
      auth
        .answerCustomChallenge(user, confirmCode)
        .then((user) => {
          setAttempting(false)
          console.log(user)
        })
        .catch((err) => {
          setAttempting(false)
          setErrorMessage(err.message)
        })
    },
    [auth, user, confirmCode]
  )

  const handleCloseModal = () => {
    setModalShow(false)
  }

  // hooks
  useEffect(() => {
    errorMessage !== "" && setModalShow(true)
  }, [errorMessage])

  useEffect(() => {
    !modalShow && setErrorMessage("")
  }, [modalShow])

  useEffect(() => {
    if (auth.currentUser) {
      // history.push("/");
      window.location.href = "/"
    }
  }, [auth.currentUser, history])

  return (
    <div className="row">
      <div className="signinlogo">
        <img src={Logo} alt="安全衛生点検チェックシートWEB版" />
        <br />
        <h1 className="h4">安全衛生点検チェックシートWEB版 管理画面</h1>
      </div>
      <div className="col-md-4 col-1"></div>
      <section className="mx-auto col-md-4 col-10 py-3">
        <Modal show={modalShow} message={errorMessage} onHide={handleCloseModal} />
        {progress === Progress.SignIn &&
          showSignIn({
            handleUsernameChange,
            handleSignIn,
            attempting,
            auth,
          })}
        {progress === Progress.Confirm &&
          showConfirm({
            handleConfirmCodeChange,
            handleConfirm,
            attempting,
          })}
      </section>
      <div className="col-md-4 col-1"></div>
    </div>
  )
}

const showSignIn = (props: any) => {
  return (
    <>
      <div className="text-center">
        <div className="h5 mt-3">メールアドレス認証</div>
      </div>
      <hr className="my-4 mx-3 text-secondary" />
      <Form className="my-4 px-3" onSubmit={props.handleSignIn}>
        <Form.Group>
          <Form.Label className="small">メールアドレス</Form.Label>
          <Form.Control
            type="email"
            name="username"
            placeholder="E-mail"
            autoComplete="username"
            disabled={props.attempting}
            onChange={props.handleUsernameChange}
            className="mb-1 w-100"
          />
          <div className="signin-btn-area mt-5">
            <Button
              type="submit"
              className="btn-primary btn-block w-100"
              disabled={props.attempting}
              onClick={props.handleSignIn}
            >
              ログイン
            </Button>
          </div>
        </Form.Group>
      </Form>
    </>
  )
}

const showConfirm = (props: any) => {
  return (
    <>
      <div className="text-center">
        <div className="h5 mt-3">メールアドレス認証</div>
      </div>
      <hr className="my-4 mx-3 text-secondary" />

      <Form className="my-4 px-3" onSubmit={props.handleConfirm}>
        <Form.Group controlId="formBasicConfirmCode">
          <Form.Label>認証コード</Form.Label>
          <Form.Control
            type="number"
            name="confirm-code"
            placeholder="123456"
            disabled={props.attempting}
            onChange={props.handleConfirmCodeChange}
            className="mb-1 w-100"
          />
        </Form.Group>
        <div className="signin-btn-area mt-5">
          <Button className="btn-primary btn-block w-100" disabled={props.attempting} onClick={props.handleConfirm}>
            認証
          </Button>
        </div>
      </Form>
    </>
  )
}

export default App
