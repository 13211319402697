import React from "react"

function App() {
  return (
    <header id="header" className="bg-dark text-white container-fluid">
      安全衛生点検チェックシートWEB版 - Admin -
    </header>
  )
}

export default App
