import React, { useEffect, useState } from "react"
import Button from "react-bootstrap/Button"
import Modal from "react-bootstrap/Modal"
import Row from "react-bootstrap/Row"
import { IAccount } from "@/lib/interface"
import { errorHandle } from "@/lib/system"

export default (props: any) => {
  const [errorMessage, setErrorMessage] = useState("")

  useEffect(() => {
    setErrorMessage("")
  }, [props.show])

  const handleDelete = async () => {
    const params = {
      Username: props.delAdmin,
    }
    try {
      const json = await props.admin?.deleteAdmin(params)
      if (json) {
        const updateAdmins = props.allAdmins?.filter((admin: IAccount) => {
          return admin.Username !== props.delAdmin
        })
        props.setAllAdmins(updateAdmins)
      }
      props.onHide()
    } catch (err) {
      setErrorMessage(errorHandle(err))
      console.log(err)
    }
  }

  return (
    <Modal show={props.show} onHide={props.onHide}>
      <Modal.Title id="contained-modal-title-vcenter"></Modal.Title>
      <Modal.Body>
        <div>{errorMessage}</div>
        <Row>
          <div className="col-6">
            <Button onClick={() => handleDelete()} className="btn-sm w-100">
              <span>削除しますか?</span>
            </Button>
          </div>
          <div className="col-6">
            <Button onClick={props.onHide} className="btn-sm w-100" variant="secondary">
              Cancel
            </Button>
          </div>
        </Row>
      </Modal.Body>
    </Modal>
  )
}
