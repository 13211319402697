import React from "react"
import { Routes, BrowserRouter as Router, Route } from "react-router-dom"

import MyRoute from "./lib/custom-route"

import UserIndex from "./pages/user/index"
import UserCreate from "./pages/user/create"
import UserDetail from "./pages/user/detail"
import AdminIndex from "./pages/admin/index"
import AdminMypage from "./pages/admin/detail"
import AdminCreate from "./pages/admin/create"
import SignIn from "./pages/auth/signin"
import SignOut from "./pages/auth/signout"
import DepartmentIndex from "./pages/department/index"
import DepartmentCreate from "./pages/department/create"
import DepartmentEdit from "./pages/department/edit"
import QuestionIndex from "./pages/question/index"
import QuestionCreate from "./pages/question/create"
import QuestionEdit from "./pages/question/edit"
import PageNotFound from "./pages/page-not-found"
import ReportIndex from "./pages/report/index"
import ReportDetail from "./pages/report/detail"
import ReportPdf from "./pages/report/pdf"
// import ReportEdit from "./pages/report/create"
import UserEdit from "./pages/user/edit"
import AdminEdit from "./pages/admin/edit"

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<MyRoute Component={<AdminMypage />} wrap auth contents id="admin-mypage" />} />
        <Route path="user">
          <Route path="" element={<MyRoute Component={<UserIndex />} wrap auth contents id="user-index" />} />
          <Route path="create" element={<MyRoute Component={<UserCreate />} wrap auth contents id="user-create" />} />
          <Route path=":id" element={<MyRoute Component={<UserDetail />} wrap auth contents id="user-detail" />} />
          <Route path="edit" element={<MyRoute Component={<UserEdit />} wrap auth contents id="user-edit" />} />
        </Route>
        <Route path="admin">
          <Route path="" element={<MyRoute Component={<AdminIndex />} wrap auth contents id="admin-index" />} />
          <Route path="create" element={<MyRoute Component={<AdminCreate />} wrap auth contents id="admin-create" />} />
          <Route path="edit" element={<MyRoute Component={<AdminEdit />} wrap auth contents id="admin-edit" />} />
        </Route>
        <Route path="auth">
          <Route
            path="signin"
            element={<MyRoute Component={<SignIn />} wrap contents signinFrame id="auth-signin" />}
          />
          <Route path="signout" element={<MyRoute Component={<SignOut />} wrap auth contents id="auth-signout" />} />
        </Route>
        <Route path="department">
          <Route
            path=""
            element={<MyRoute Component={<DepartmentIndex />} wrap auth contents id="department-index" />}
          />
          <Route
            path="create"
            element={<MyRoute Component={<DepartmentCreate />} wrap auth contents id="department-create" />}
          />
          <Route
            path=":id/edit"
            element={<MyRoute Component={<DepartmentEdit />} wrap auth contents id="department-edit" />}
          />
        </Route>

        <Route path="report">
          <Route path="" element={<MyRoute Component={<ReportIndex />} wrap auth contents id="report-index" />} />
          <Route path=":id" element={<MyRoute Component={<ReportDetail />} wrap auth contents id="report-detail" />} />
          <Route path=":id/pdf" element={<MyRoute Component={<ReportPdf />} wrap auth contents id="report-pdf" />} />
          {/* <Route path=":id/edit" element={<MyRoute Component={<ReportEdit />} wrap auth contents id="report-edit" />} /> */}
        </Route>

        <Route path="question">
          <Route path="" element={<MyRoute Component={<QuestionIndex />} wrap auth contents id="question-index" />} />
          <Route
            path="create"
            element={<MyRoute Component={<QuestionCreate />} wrap auth contents id="question-create" />}
          />
          <Route
            path=":id/edit"
            element={<MyRoute Component={<QuestionEdit />} wrap auth contents id="question-edit" />}
          />
        </Route>
        <Route path="*" element={<PageNotFound />} />
      </Routes>
    </Router>
  )
}

export default App
