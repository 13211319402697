import React, { useContext, useEffect } from "react"
import { useNavigate } from "react-router-dom"
import { AuthContext } from "@/lib/auth"

function App() {
  const history = useNavigate()

  const auth = useContext(AuthContext)

  useEffect(() => {
    auth.signOut().then(() => {
      history("/auth/signin")
    })
  }, [auth, history])

  return <React.Fragment />
}

export default App
