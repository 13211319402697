import AWS from "aws-sdk"
import { Auth } from "aws-amplify"

class Admin {
  private provider: any
  public idToken = ""
  private callback: (data: any) => any

  constructor(__callback: (data: any) => any) {
    this.callback = __callback
    Auth.currentSession()
      .then((session: any) => {
        this.idToken = session.getIdToken().getJwtToken()
        this.setup()
      })
      .catch((error: any) => {
        console.log(error)
      })
  }

  private setup = () => {
    const region = process.env.REACT_APP_AWS_COGNITO_REGION as string
    const userPoolsId = process.env.REACT_APP_AWS_USER_POOLS_ID as string
    const identityPoolId = process.env.REACT_APP_AWS_IDENTITY_POOL_ID as string
    const key = `cognito-idp.${region}.amazonaws.com/${userPoolsId}`

    const credentials = new AWS.CognitoIdentityCredentials({
      IdentityPoolId: identityPoolId,
      Logins: { [key]: this.idToken },
    })

    AWS.config.region = region
    AWS.config.credentials = credentials

    this.provider = new AWS.CognitoIdentityServiceProvider()
    this.callback(this)
  }

  public listAdmins = async (__params: any): Promise<any> => {
    const params = {
      ...__params,
      UserPoolId: process.env.REACT_APP_AWS_USER_POOLS_ID as string,
    }
    return new Promise<any>((resolve: (data: any) => void, reject: (reason?: any) => void) => {
      this.provider.listUsers(params, (err: any, data: any) => {
        err ? reject(err.message) : resolve(data)
      })
    })
  }

  public deleteAdmin = async (__params: any): Promise<any> => {
    const params = {
      ...__params,
      UserPoolId: process.env.REACT_APP_AWS_USER_POOLS_ID as string,
    }
    return new Promise<any>((resolve: (data: any) => void, reject: (reason?: any) => void) => {
      this.provider.adminDeleteUser(params, (err: any, data: any) => {
        err ? reject(err.message) : resolve(data)
      })
    })
  }

  public enableAdmin = async (__params: any): Promise<any> => {
    const params = {
      ...__params,
      UserPoolId: process.env.REACT_APP_AWS_USER_POOLS_ID as string,
    }
    return new Promise<any>((resolve: (data: any) => void, reject: (reason?: any) => void) => {
      this.provider.adminEnableUser(params, (err: any, data: any) => {
        err ? reject(err.message) : resolve(data)
      })
    })
  }

  public disableAdmin = async (__params: any): Promise<any> => {
    const params = {
      ...__params,
      UserPoolId: process.env.REACT_APP_AWS_USER_POOLS_ID as string,
    }
    return new Promise<any>((resolve: (data: any) => void, reject: (reason?: any) => void) => {
      this.provider.adminDisableUser(params, (err: any, data: any) => {
        err ? reject(err.message) : resolve(data)
      })
    })
  }

  public createAdmin = async (__params: any): Promise<any> => {
    const params = {
      ...__params,
      UserPoolId: process.env.REACT_APP_AWS_USER_POOLS_ID as string,
    }
    return new Promise<any>((resolve: (data: any) => void, reject: (reason?: any) => void) => {
      this.provider.adminCreateUser(params, (err: any, data: any) => {
        err ? reject(err.message) : resolve(data)
      })
    })
  }

  public getUser = (__params: any): Promise<any> => {
    const params = {
      ...__params,
      UserPoolId: process.env.REACT_APP_AWS_SERVICE_USER_POOLS_ID as string,
    }
    return new Promise<any>((resolve: (data: any) => void, reject: (reason?: any) => void) => {
      this.provider.adminGetUser(params, (err: any, data: any) => {
        err ? reject(err.message) : resolve(data)
      })
    })
  }

  public updateUserAttributes = (__params: any): Promise<any> => {
    const params = {
      ...__params,
      UserPoolId: process.env.REACT_APP_AWS_SERVICE_USER_POOLS_ID as string,
    }
    return new Promise<any>((resolve: (data: any) => void, reject: (reason?: any) => void) => {
      this.provider.adminUpdateUserAttributes(params, (err: any, data: any) => {
        err ? reject(err.message) : resolve(data)
      })
    })
  }

  public listUsers = (__params: any): Promise<any> => {
    const params = {
      ...__params,
      UserPoolId: process.env.REACT_APP_AWS_SERVICE_USER_POOLS_ID as string,
    }
    return new Promise<any>((resolve: (data: any) => void, reject: (reason?: any) => void) => {
      this.provider.listUsers(params, (err: any, data: any) => {
        err ? reject(err.message) : resolve(data)
      })
    })
  }

  public deleteUser = (__params: any): Promise<any> => {
    const params = {
      ...__params,
      UserPoolId: process.env.REACT_APP_AWS_SERVICE_USER_POOLS_ID as string,
    }
    return new Promise<any>((resolve: (data: any) => void, reject: (reason?: any) => void) => {
      this.provider.adminDeleteUser(params, (err: any, data: any) => {
        err ? reject(err.message) : resolve(data)
      })
    })
  }

  public enableUser = (__params: any): Promise<any> => {
    const params = {
      ...__params,
      UserPoolId: process.env.REACT_APP_AWS_SERVICE_USER_POOLS_ID as string,
    }
    return new Promise<any>((resolve: (data: any) => void, reject: (reason?: any) => void) => {
      this.provider.adminEnableUser(params, (err: any, data: any) => {
        err ? reject(err.message) : resolve(data)
      })
    })
  }

  public disableUser = (__params: any): Promise<any> => {
    const params = {
      ...__params,
      UserPoolId: process.env.REACT_APP_AWS_SERVICE_USER_POOLS_ID as string,
    }
    return new Promise<any>((resolve: (data: any) => void, reject: (reason?: any) => void) => {
      this.provider.adminDisableUser(params, (err: any, data: any) => {
        err ? reject(err.message) : resolve(data)
      })
    })
  }

  public createUser = (__params: any): Promise<any> => {
    const params = {
      ...__params,
      UserPoolId: process.env.REACT_APP_AWS_SERVICE_USER_POOLS_ID as string,
    }
    return new Promise<any>((resolve: (data: any) => void, reject: (reason?: any) => void) => {
      this.provider.adminCreateUser(params, (err: any, data: any) => {
        err ? reject(err.message) : resolve(data)
      })
    })
  }

  public setUserPassword = (__params: any): Promise<any> => {
    const params = {
      ...__params,
      UserPoolId: process.env.REACT_APP_AWS_SERVICE_USER_POOLS_ID as string,
    }
    return new Promise<any>((resolve: (data: any) => void, reject: (reason?: any) => void) => {
      this.provider.adminSetUserPassword(params, (err: any, data: any) => {
        err ? reject(err.message) : resolve(data)
      })
    })
  }

  public generateRandomString = (num: number): string => {
    const characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789"
    let result = ""
    const charactersLength = characters.length
    for (let i = 0; i < num; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength))
    }
    return result
  }
}

export default Admin
