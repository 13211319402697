import type { IOperatorOther, IReportComments } from "@/lib/interface"
export function parseComment(section: string | undefined): IReportComments | undefined {
  if (section === undefined) {
    return undefined
  }
  const parts = section.split(", ")
  if (parts.length < 2 || !parts[0].includes(": ") || !parts[1].includes(": ")) {
    throw new Error("Invalid comment format")
  }
  const authorPart = parts[0].split(/:\s*/)[1]
  const commentPart = parts[1].split(/:\s*/)[1]
  return { author: authorPart, comment: commentPart }
}

export function parseComments(comments: string | undefined): IReportComments[] {
  if (comments === undefined) {
    return []
  }
  try {
    const spritCommentData = comments.split("; ")
    return spritCommentData
      .map((comment) => parseComment(comment))
      .filter((comment): comment is IReportComments => comment !== undefined)
  } catch (error) {
    console.error("Error parsing comments:", error)
    return [{ author: "", comment: comments }]
  }
}

export function parseOperatorsOther(other: string | undefined): IOperatorOther | undefined {
  if (other === undefined) {
    return undefined
  }
  const parts = other.split(", ")
  if (parts.length < 2 || !parts[0].includes(": ") || !parts[1].includes(": ")) {
    throw new Error("Invalid operator other format")
  }
  const otherNamePart = parts[0].split(/:\s*/)[1]
  const otherNumberPartRaw = parts[1].split(/:\s*/)[1]
  const otherNumberPart = parseStringToDecimal(otherNumberPartRaw)

  return { other_name: otherNamePart, other_number: otherNumberPart }
}

export function parseStringToDecimal(value: string): number | null {
  if (value === undefined || value === "") {
    return null
  }
  const parsedValue = Number.parseInt(value, 10) // 10進数として解釈

  // other_numberがNaNである場合のエラーチェック
  if (Number.isNaN(parsedValue)) {
    throw new Error("Invalid number format for other_number")
  }
  // otherNumberPartRawが完全に数値であることを確認（オプション）
  if (!/^\d+$/.test(value)) {
    throw new Error("other_number must be a valid integer")
  }

  // 範囲検証（例: other_numberが0以上であることを確認）
  if (parsedValue < 0) {
    throw new Error("other_number must be non-negative")
  }

  return parsedValue
}

export function parseOperatorsOthers(others: string | IOperatorOther[] | null | undefined): IOperatorOther[] {
  if (Array.isArray(others)) {
    return others
  }
  if (others === undefined) {
    return []
  }
  if (others === null) {
    return [{ other_name: "", other_number: 0 }]
  }
  try {
    const spritOperatorData = others.split("; ")
    return spritOperatorData
      .map((operator) => parseOperatorsOther(operator))
      .filter((operator): operator is IOperatorOther => operator !== undefined)
  } catch (error) {
    console.error("Error parsing operators:", error)
    return [{ other_name: others, other_number: 0 }]
  }
}

export function removeQueryString(text: string): string {
  const questionMarkIndex = text.indexOf("?")
  if (questionMarkIndex !== -1) {
    return text.substring(0, questionMarkIndex)
  }
  return text
}
