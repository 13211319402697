import React, { useEffect, useState } from "react"
import { Link, useParams } from "react-router-dom"
import Breadcrumb from "react-bootstrap/Breadcrumb"
import { Modal } from "../common"
import QuestionForm from "./_question_form"

function App() {
  const [errorMessage, setErrorMessage] = useState("")
  const [modalShow, setModalShow] = useState(false)
  const params = useParams<{ id: string }>()

  const handleCloseModal = () => {
    setModalShow(false)
  }

  useEffect(() => {
    errorMessage !== "" && setModalShow(true)
  }, [errorMessage])

  useEffect(() => {
    !modalShow && setErrorMessage("")
  }, [modalShow])

  return (
    <>
      <Modal show={modalShow} message={errorMessage} onHide={handleCloseModal} />
      <h1 className="h4">チェックリスト更新</h1>
      <hr />
      <Breadcrumb className="mb-4 small bg-light">
        <Breadcrumb.Item linkAs="span">
          <Link to="/">ホーム</Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item linkAs="span">
          <Link to="/question/">チェックリスト一覧</Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item active>新規作成</Breadcrumb.Item>
      </Breadcrumb>
      <p className="text-danger">＊点検分類の改廃は安全衛生推進室のみ＊</p>
      <QuestionForm id={params.id} setErrorMessage={setErrorMessage} />
    </>
  )
}

export default App
