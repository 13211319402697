import React, { useEffect, useState, useRef } from "react"
import { CopyToClipboard } from "react-copy-to-clipboard"
import { Link, useParams } from "react-router-dom"
import Breadcrumb from "react-bootstrap/Breadcrumb"
import Button from "react-bootstrap/Button"
import Table from "react-bootstrap/Table"
import Spinner from "react-bootstrap/Spinner"
import Overlay from "react-bootstrap/Overlay"
import Tooltip from "react-bootstrap/Tooltip"
import { ClipboardCheck } from "react-bootstrap-icons"
import QRCode from "qrcode.react"
import { Admin } from "@/admin"
import * as api from "@/lib/api"
import { IAccountAttributes } from "@/lib/interface"

function App() {
  const [admin, setAdmin] = useState<Admin | null>(null)
  const [user, setUser] = useState<any>(null)
  const params = useParams<any>()
  const [token, setToken] = useState("")
  const [url, setUrl] = useState("")
  const [password, setPassword] = useState("")
  const [isLoaded, setIsLoaded] = useState<boolean>(true)
  const [isDetailLoaded, setIsDetailLoaded] = useState<boolean>(true)
  const [toolTipShow, setToolTipShow] = useState(false)
  const toolTipTarget = useRef(null)

  useEffect(() => {
    new Admin(setAdmin)
  }, [])

  const createParams = () => {
    const reqParams = {
      Filter: `sub^="${params.id}"`,
      Limit: 1,
    }
    return reqParams
  }

  useEffect(() => {
    setIsLoaded(true)
    setIsDetailLoaded(true)
    if (admin && params.id) {
      admin
        .listUsers(createParams())
        .then((data) => {
          setUser(data.Users[0])
        })
        .catch(console.log)
    }
  }, [admin, params.id])

  useEffect(() => {
    if (user) {
      const sub = user.Attributes.filter((v: IAccountAttributes) => v.Name === "sub")[0]?.Value
      getPasswordDb(sub)
    }
    setIsLoaded(false)
  }, [user])

  const getPasswordDb = async (sub: string) => {
    try {
      const json: any = await api.getPasswordDb(sub)
      if (json?.data) {
        setToken(json.data.token)
        setPassword(json.data.password)
      }
    } catch (err) {
      console.log(err)
    }
    setIsDetailLoaded(false)
  }

  useEffect(() => {
    if (token) {
      setUrl(
        window.location.hostname === "localhost"
          ? `http://localhost:3000/auth/signin/${token}`
          : `${process.env.REACT_APP_USER_LOGIN_URL}/${token}`
      )
    }
  }, [token])

  const downloadQRCode = () => {
    const qrCode = document.getElementById("qr-gen") as HTMLCanvasElement
    const qrCodeURL = qrCode.toDataURL("image/png").replace("image/png", "image/octet-stream")
    console.log(qrCodeURL)
    const aEl = document.createElement("a")
    aEl.href = qrCodeURL
    aEl.download = "QR_Code.png"
    document.body.appendChild(aEl)
    aEl.click()
    document.body.removeChild(aEl)
  }

  return (
    <>
      {isLoaded && (
        <div className="w-100 mt-5 text-center">
          <Spinner animation="border" variant="info" />
        </div>
      )}
      {!isLoaded && user && (
        <>
          <h1 className="h4">ユーザ詳細</h1>

          <hr />

          <Breadcrumb className="mb-4 small bg-light">
            <Breadcrumb.Item linkAs="span">
              <Link to="/">ホーム</Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item linkAs="span">
              <Link to="/user/">ユーザ一覧</Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item active>{user?.Username}</Breadcrumb.Item>
          </Breadcrumb>

          <Table className="user-table">
            <tbody>
              <tr>
                <th>状態</th>
                <td>
                  {user?.Enabled && <span className="bg-primary d-inline-block px-1 text-light">有効</span>}
                  {!user.Enabled && <span className="bg-danger d-inline-block px-1 text-light">無効</span>}
                </td>
              </tr>
              <tr>
                <th>ID</th>
                <td>{user?.Username}</td>
              </tr>
              <tr>
                <th>パスワード</th>
                <td>
                  {isDetailLoaded && <Spinner animation="border" variant="info" />}
                  {!isDetailLoaded && !password && <div className="alert alert-danger">正常に取得できません</div>}
                  {!isDetailLoaded && password}
                </td>
              </tr>
              <tr>
                <th>共有URL</th>
                <td>
                  {isDetailLoaded && <Spinner animation="border" variant="info" />}
                  {!isDetailLoaded && (
                    <>
                      <div className="row">
                        <div className="col-3">
                          <QRCode value={url} id="qr-gen" />
                        </div>
                        <div className="col-9">
                          <button type="button" className="btn btn-primary m-3" onClick={downloadQRCode}>
                            QR Code ダウンロード
                          </button>
                        </div>
                      </div>
                      {url}
                      <CopyToClipboard text={url}>
                        <div
                          ref={toolTipTarget}
                          onClick={() => setToolTipShow(!toolTipShow)}
                          className="d-inline-block"
                        >
                          <ClipboardCheck size={20} className="ms-2 mb-1 clipboard align-middle" />
                        </div>
                      </CopyToClipboard>
                      <Overlay target={toolTipTarget.current} show={toolTipShow} placement="right">
                        {(props) => <Tooltip {...props}>コピーしました</Tooltip>}
                      </Overlay>
                    </>
                  )}
                  {!isDetailLoaded && !token && <div className="alert alert-danger">正常に取得できません</div>}
                </td>
              </tr>
            </tbody>
          </Table>
          <div className="row">
            <div className="col-6 text-right">
              <Button size="sm" href={`/user/`} className="btn-secondary btn-sm">
                一覧へ戻る
              </Button>
            </div>
          </div>
        </>
      )}
    </>
  )
}

export default App
