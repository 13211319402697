import React, { useEffect, useState } from "react"
import Table from "react-bootstrap/Table"
import Form from "react-bootstrap/Form"
import Button from "react-bootstrap/Button"
import { Link } from "react-router-dom"
import { IDepartment, IInspection, IQuestion } from "@/lib/interface"
import * as api from "@/lib/api"
import DeleteModal from "./_delete_modal"

function App() {
  //デフォルトでチェックをonにするためだけの設定
  const [inspectionIds, setInspectionIds] = useState<string[]>([])
  const [inspections, setInspections] = useState<IInspection[]>([])
  const [departmentIds, setDepartmentIds] = useState<string[]>([])
  const [departments, setDepartments] = useState<IDepartment[]>([])
  const [questions, setQuestions] = useState<IQuestion[]>([])
  const [deleteModalShow, setDeleteModalShow] = useState(false)
  const [delQuestionId, setDelQuestionId] = useState("")

  useEffect(() => {
    getSelect()
  }, [])

  const getSelect = async () => {
    try {
      const inspectionJson: any = await api.getInspectionsDb()
      console.log(inspectionJson)
      if (inspectionJson?.data) {
        setInspections(inspectionJson?.data.json)
        setInspectionIds(
          inspectionJson?.data.json.map((inspection: IInspection) => {
            return inspection.id
          })
        )
      }
      const departmentJson = await api.getDepartmentsDb("", "")
      console.log(departmentJson)
      if (departmentJson?.data) {
        setDepartments(departmentJson?.data.json)
      }
      const questionJson: any = await api.getQuestionsDb()
      console.log(questionJson)
      if (questionJson?.data.json) {
        setQuestions(questionJson.data.json)
      }
    } catch (err) {
      console.log(err)
    }
  }

  const handleInspectionsChange = (e: any) => {
    if (inspectionIds?.includes(e.target.value)) {
      setInspectionIds(
        inspectionIds.filter((id: string) => {
          return id != e.target.value
        })
      )
    } else {
      setInspectionIds([...(inspectionIds || []), e.target.value])
    }
  }

  const handleDepartmentChange = (e: any) => {
    if (departmentIds?.includes(e.target.value)) {
      setDepartmentIds(
        departmentIds.filter((id: string) => {
          return id != e.target.value
        })
      )
    } else {
      setDepartmentIds([...(departmentIds || []), e.target.value])
    }
  }

  const handleDelete = async (id: string) => {
    setDeleteModalShow(true)
    setDelQuestionId(id)
  }

  return (
    <React.Fragment>
      <DeleteModal
        delQuestionId={delQuestionId}
        setQuestions={setQuestions}
        questions={questions}
        show={deleteModalShow}
        onHide={() => setDeleteModalShow(false)}
      />
      <div className="row">
        <h1 className="h4 col-12 col-md-5">チェックリスト</h1>
        <div className="col-md-4"></div>
        <div className="col-12 col-md-3">
          <Link to={`/question/create/`} className="btn btn-orange fw-bold float-end w-100">
            新しくチェックリストを追加する
          </Link>
        </div>
        <hr />
      </div>
      <div className="row border rounded mx-2 px-2 mb-5 pt-3 pb-0">
        <h5 className="fw-bold">点検分類 / 部門で絞り込む</h5>
        <p className="text-danger">＊点検分類の改廃は安全衛生推進室のみ＊</p>
        <div className="col-12 py-2">
          <div className="bg-info bg-opacity-25 p-2 mb-2 fw-bold">点検分類</div>
          <Form className="row">
            {inspections?.map((inspection: IInspection, index: number) => {
              return (
                <div key={`inspection-${index}`} className="col-12 col-md-3">
                  <div className="bg-light m-1 p-1">
                    <Form.Check
                      inline
                      type="switch"
                      id={`inspection-${index}`}
                      name="inspection"
                      label={`${inspection.view_number}. ${inspection.name}`}
                      value={inspection.id}
                      checked={inspectionIds?.includes(inspection.id || "")}
                      onChange={handleInspectionsChange}
                      className="m-0"
                    />
                  </div>
                </div>
              )
            })}
          </Form>
        </div>
        <div className="col-12 py-2">
          <div className="bg-warning bg-opacity-25 p-2 mb-2 fw-bold">部門独自項目</div>
          <Form className="row">
            {departments?.map((department: IDepartment, index: number) => {
              return (
                <div key={`inspection-${index}`} className="col-12 col-md-3">
                  <div className="bg-light m-1 p-1">
                    <Form.Check
                      inline
                      type="switch"
                      id={`department-${index}`}
                      name="department"
                      label={`${department.view_number}. ${department.name}`}
                      value={department.id}
                      checked={departmentIds?.includes(department.id || "")}
                      onChange={handleDepartmentChange}
                      className="m-0"
                    />
                  </div>
                </div>
              )
            })}
          </Form>
        </div>
      </div>
      <div className="row">
        <h1 className="h4 col-12 col-md-5">チェックリスト一覧</h1>
        <hr className="my-1" />
        <div className="alert alert-warning">
          ステータスが[表示]となっているチェック項目がユーザ側に表示されます。
          <br />
          回答されたレポート数が <span className="text-danger">0</span>
          、且つ[非表示]のチェック項目のみ削除可能です
        </div>
        <Form className="mt-4">
          <div className="d-md-block d-lg-none alert alert-success">横にスクロールでできます</div>
          <div className="table-responsive">
            <Table hover className="w-100">
              <thead className="small font-weight-normal border-top bg-light text-center">
                <tr>
                  <th className="align-middle"></th>
                  <th className="align-middle text-nowrap">
                    <span className="bg-info bg-opacity-25 p-2 mx-1">点検分類</span>/
                    <span className="bg-warning bg-opacity-25 p-2 mx-1">部門</span>
                  </th>
                  <th className="align-middle text-nowrap">表示順</th>
                  <th className="align-middle">大項目</th>
                  <th className="align-middle" style={{ minWidth: "200px" }}>
                    チェック内容
                  </th>
                  <th className="align-middle">レポート数</th>
                  <th className="align-middle"></th>
                </tr>
              </thead>
              <tbody>
                {questions
                  ?.filter((question: IQuestion) => {
                    return (
                      inspectionIds?.includes(question.inspection_id || "") ||
                      departmentIds?.includes(question.department_id || "")
                    )
                  })
                  ?.map((question: IQuestion, index: number) => {
                    return (
                      <tr
                        key={`question-${index}`}
                        className={`${question.status !== 1 ? "bg-secondary bg-opacity-25" : ""}`}
                      >
                        <td className="small text-center align-middle text-nowrap">
                          {question.status !== 1 && (
                            <span className="bg-secondary text-light small p-1 rounded">非表示</span>
                          )}
                          {question.status === 1 && (
                            <span className="bg-primary text-light small p-1 rounded">表示中</span>
                          )}
                        </td>
                        <td
                          className={`${
                            question.inspection_id ? "bg-info bg-opacity-25" : "bg-warning bg-opacity-25"
                          } align-middle text-nowrap`}
                        >
                          {question.inspection_id &&
                            inspections?.find((inspection: IInspection) => {
                              return inspection.id === question.inspection_id
                            })?.view_number}
                          {question.department_id &&
                            departments?.find((department: IDepartment) => {
                              return department.id === question.department_id
                            })?.view_number}
                          .
                          {question.inspection_id &&
                            inspections?.find((inspection: IInspection) => {
                              return inspection.id === question.inspection_id
                            })?.name}
                          {question.department_id &&
                            departments?.find((department: IDepartment) => {
                              return department.id === question.department_id
                            })?.name}
                        </td>
                        <td className="align-middle text-center">{question.view_number}</td>
                        <td className="align-middle text-break">{question.class_name}</td>
                        <td className="align-middle">{question.name}</td>
                        <td className="align-middle">{question.report_count}</td>
                        <td className="align-middle text-center text-nowrap">
                          <Link to={`/question/${question.id}/edit`} className="btn btn-sm btn-primary btn-block">
                            更新
                          </Link>
                          {question.status !== 1 && question.report_count === 0 && (
                            <Button
                              onClick={() => handleDelete(question.id || "")}
                              className="btn-sm btn-block btn-danger"
                            >
                              削除
                            </Button>
                          )}
                        </td>
                      </tr>
                    )
                  })}
              </tbody>
            </Table>
          </div>
        </Form>
      </div>
    </React.Fragment>
  )
}

export default App
