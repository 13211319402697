import React, { useCallback, useEffect, useState } from "react"
import Table from "react-bootstrap/Table"
import Form from "react-bootstrap/Form"
import Button from "react-bootstrap/Button"
import { format } from "date-fns"
import { Link } from "react-router-dom"
import { IDepartment, ISortType } from "@/lib/interface"
import * as api from "@/lib/api"
import DeleteModal from "./_delete_modal"

function App() {
  const [departments, setDepartments] = useState<IDepartment[]>([])
  const [searchText, setSearchText] = useState<string>("")
  const [attempting] = useState(false)
  const [sortType, setSortType] = useState<ISortType>()
  const [deleteModalShow, setDeleteModalShow] = useState(false)
  const [delDepartmentId, setDelDepartmentId] = useState("")

  useEffect(() => {
    getDepartments()
  }, [sortType])

  const getDepartments = async () => {
    const json = await api.getAllDepartmentsDb(searchText, sortType?.type, sortType?.order)
    if (json?.data) {
      setDepartments(json?.data.json)
    }
  }

  const handleNameChange = useCallback((e: any) => setSearchText(e.target.value), [])

  const handleSortTypeChange = (e: { currentTarget: { id: string } }) => {
    //typeが切り替わるタイミングで"asc"にする
    if (sortType?.order === "desc") {
      setSortType({ type: e.currentTarget.id, order: "asc" })
    } else {
      setSortType({ type: e.currentTarget.id, order: "desc" })
    }
  }

  const handleDelete = async (id: string) => {
    setDeleteModalShow(true)
    setDelDepartmentId(id)
  }

  return (
    <>
      <DeleteModal
        delDepartmentId={delDepartmentId}
        setDepartments={setDepartments}
        departments={departments}
        show={deleteModalShow}
        onHide={() => setDeleteModalShow(false)}
      />
      <div className="row">
        <h1 className="h4 col-12 col-md-5">部門一覧</h1>
        <div className="col-md-4"></div>
        <div className="col-12 col-md-3">
          <Link to={`/department/create/`} className="btn btn-orange fw-bold float-end w-100">
            新しく部門を追加する
          </Link>
        </div>
      </div>
      <hr />
      <Form className="search-area mb-3">
        <div className="row mb-4">
          <div className="col-md-4 col-12">
            <Form.Group>
              <Form.Control placeholder="名称" type="text" disabled={attempting} onChange={handleNameChange} />
            </Form.Group>
          </div>
          <div className="col-md-4 col-12 mt-2 mt-md-0">
            <Button disabled={attempting} onClick={getDepartments} className="search-btn btn-block">
              検索
            </Button>
          </div>
        </div>
      </Form>
      <div className="alert alert-warning">
        ステータスが[表示]となっている部門がユーザ側に表示されます。
        <br />
        関連するレポート数が <span className="text-danger">0</span>
        、且つ独自項目数が <span className="text-danger">0</span>
        、[非表示]の部門のみ削除可能です
      </div>
      <div className="table-responsive-lg">
        <Table hover className="text-center user-table">
          <thead className="small fw-normal border-top bg-light text-nowrap text-center">
            <tr>
              <th scope="col">
                <Link to="#" onClick={handleSortTypeChange} className="link" id="view_number">
                  {sortType?.type === "view_number" && sortType?.order === "desc" ? "▼" : "▲"}
                </Link>
                表示順
              </th>
              <th scope="col">名称</th>
              <th scope="col">
                <Link to="#" onClick={handleSortTypeChange} className="link" id="update_date">
                  {sortType?.type === "update_date" && sortType?.order === "desc" ? "▼" : "▲"}
                </Link>
                更新日
              </th>
              <th scope="col">
                <Link to="#" onClick={handleSortTypeChange} className="link" id="status">
                  {sortType?.type === "status" && sortType?.order === "desc" ? "▼" : "▲"}
                </Link>
                ステータス
              </th>
              <th scope="col">
                <Link to="#" onClick={handleSortTypeChange} className="link" id="report_count">
                  {sortType?.type === "report_count" && sortType?.order === "desc" ? "▼" : "▲"}
                </Link>
                レポート数
              </th>
              <th scope="col">独自チェックリスト数</th>
              <th scope="col">操作</th>
            </tr>
          </thead>
          <tbody>
            {departments?.length > 0 &&
              departments?.map((department: IDepartment) => {
                return (
                  <tr key={department.id}>
                    <td>{department.view_number}</td>
                    <td>{department.name}</td>
                    <td>{format(new Date(department.update_date || ""), "yyyy-MM-dd HH:mm:ss")}</td>
                    <td>
                      {department.status !== 1 && (
                        <span className="bg-secondary p-1 text-light rounded small">非表示</span>
                      )}
                      {department.status === 1 && (
                        <span className="bg-primary p-1 text-light rounded small">表示中</span>
                      )}
                    </td>
                    <td>{department.report_count}</td>
                    <td>{department.question_count}</td>
                    <td>
                      <Link to={`/department/${department.id}/edit`} className="btn btn-sm btn-primary btn-block">
                        更新
                      </Link>
                      {department.status !== 1 && department.report_count === 0 && department.question_count === 0 && (
                        <Button
                          onClick={() => handleDelete(department.id || "")}
                          className="btn-sm btn-block btn-danger ms-2"
                        >
                          削除
                        </Button>
                      )}
                    </td>
                  </tr>
                )
              })}
          </tbody>
        </Table>
      </div>
    </>
  )
}

export default App
