import React, { useCallback, useEffect, useState } from "react"
import { Link, useNavigate } from "react-router-dom"
import Breadcrumb from "react-bootstrap/Breadcrumb"
import Button from "react-bootstrap/Button"
import Form from "react-bootstrap/Form"
import { Admin } from "@/admin"
import * as api from "@/lib/api"
import { IAccountAttributes, IDepartment, isIDepartmentArray } from "@/lib/interface"
import { errorHandle } from "@/lib/system"
import { Modal } from "../common"

enum Progress {
  Preparing,
  Attempting,
}

function App() {
  const [admin, setAdmin] = useState<Admin>()
  const [progress, setProgress] = useState(Progress.Preparing)
  const [errorMessage, setErrorMessage] = useState("")
  const [modalShow, setModalShow] = useState(false)
  const [id, setId] = useState("")
  const [password, setPassword] = useState("")
  const [name, setName] = useState("")
  const [inputDepartment, setInputDepartment] = useState("")
  const [departmentsList, setDepartmentsList] = useState<IDepartment[]>([])

  const handleIdChange = useCallback((e: any) => setId(e.target.value), [])
  const handlePasswordChange = useCallback((e: any) => setPassword(e.target.value), [])
  const handleNameChange = useCallback((e: any) => setName(e.target.value), [])
  const handleDepartmentChange = useCallback((e: any) => setInputDepartment(e.target.value), [])
  const history = useNavigate()

  const handleCloseModal = () => {
    setModalShow(false)
  }

  async function fetchDepartments(): Promise<IDepartment[]> {
    try {
      const fetchData = await api.getDepartmentsDb()
      if (!isIDepartmentArray(fetchData.data.json)) {
        return []
      }
      return fetchData.data.json
    } catch (err) {
      console.log(err)
      return []
    }
  }

  useEffect(() => {
    new Admin(setAdmin)
    const initialize = async () => {
      const departmentsList = await fetchDepartments()
      setDepartmentsList(departmentsList)
    }
    initialize()
  }, [])

  useEffect(() => {
    errorMessage !== "" && setModalShow(true)
  }, [errorMessage])

  useEffect(() => {
    !modalShow && setErrorMessage("")
  }, [modalShow])

  const handleSave = async () => {
    setProgress(Progress.Preparing)
    if (admin) {
      try {
        if (!id) {
          throw new Error("IDは必須です")
        }
        if (!password) {
          throw new Error("パスワードは必須です")
        }
        if (id?.length > 100) {
          throw new Error("IDは100文字以内で入力してください")
        }
        if (password?.length < 8 || password?.length > 20) {
          throw new Error("パスワードは8文字以上20文字以内で入力してください")
        }
        const createParams = {
          Username: id,
        }
        const passwordParams = {
          Username: id,
          Password: password,
          Permanent: true,
        }
        const createJson = await admin.createUser(createParams)
        const passwordJson = await admin.setUserPassword(passwordParams)
        if (createJson?.User?.Attributes?.filter((v: IAccountAttributes) => v.Name === "sub")[0]?.Value) {
          const sub = createJson?.User?.Attributes?.filter((v: IAccountAttributes) => v.Name === "sub")[0]?.Value
          const setPasswordDbJson = await api.setPasswordDb(id, password, sub)
          const setUserData = await api.setUserDataDb(sub, name, inputDepartment)
        }
        history(`/user/`)
      } catch (err) {
        setErrorMessage(errorHandle(err))
        console.log(err)
      } finally {
        setProgress(Progress.Preparing)
      }
    }
  }

  return (
    <>
      <Modal show={modalShow} message={errorMessage} onHide={handleCloseModal} />
      <h1 className="h4">ユーザー新規作成</h1>
      <hr />
      <Breadcrumb className="mb-4 small bg-light">
        <Breadcrumb.Item linkAs="span">
          <Link to="/">ホーム</Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item linkAs="span">
          <Link to="/user/">ユーザ一 一覧</Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item active>新規作成</Breadcrumb.Item>
      </Breadcrumb>

      <div className="row mb-3">
        <div className="col-md-2 col-12 col-auto col-form-label fw-bold">ID</div>
        <div className="col-md-4 col-12">
          <Form>
            <Form.Control
              type="text"
              name="id"
              value={id}
              placeholder="ID"
              disabled={progress === Progress.Attempting}
              onChange={handleIdChange}
              maxLength={100}
            />
          </Form>
        </div>
      </div>
      <div className="row mb-3">
        <div className="col-md-2 col-12 col-auto col-form-label fw-bold">氏名</div>
        <div className="col-md-4 col-12">
          <Form>
            <Form.Control
              type="text"
              name="name"
              value={name}
              placeholder="氏名"
              disabled={progress === Progress.Attempting}
              onChange={handleNameChange}
              maxLength={100}
            />
          </Form>
        </div>
      </div>
      <div className="row mb-3">
        <div className="col-md-2 col-12 col-auto col-form-label fw-bold">部門</div>
        <div className="col-md-4 col-12">
          <Form>
            <Form.Select
              name="department"
              value={inputDepartment}
              placeholder="部門名"
              disabled={progress === Progress.Attempting}
              onChange={handleDepartmentChange}
            >
              {/* 無効な初期値の追加 */}
              <option value="">部門を選択してください</option>
              {departmentsList.map((department: IDepartment) => (
                <option key={department.id} value={department.id}>
                  {department.name}
                </option>
              ))}
            </Form.Select>
          </Form>
        </div>
      </div>
      <div className="row mb-3">
        <div className="col-md-2 col-12">
          <label className="col-auto col-form-label fw-bold">パスワード</label>
        </div>
        <div className="col-md-4 col-12">
          <Form>
            <Form.Control
              type="password"
              name="password"
              value={password}
              placeholder="password"
              disabled={progress === Progress.Attempting}
              onChange={handlePasswordChange}
              maxLength={20}
            />
          </Form>
        </div>
      </div>
      <div className="row mt-5">
        <div className="col-md-2 col-12">
          <Button className="text-right w-100" disabled={progress === Progress.Attempting} onClick={handleSave}>
            保存
          </Button>
        </div>
      </div>
    </>
  )
}

export default App
