import React from "react"
import Button from "react-bootstrap/Button"
import Modal from "react-bootstrap/Modal"
import { useTranslation } from "react-i18next"

function App(props: any) {
  const { t } = useTranslation()

  return (
    <Modal {...props} size="md" aria-labelledby="contained-modal-title-vcenter" centered>
      <Modal.Header closeButton className="bg-light">
        <Modal.Title id="contained-modal-title-vcenter fw-bold">{props.title || "エラーです"}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>{t(props.message)}</p>
      </Modal.Body>
      <Modal.Footer className="bg-light">
        <Button onClick={props.onHide}>Close</Button>
      </Modal.Footer>
    </Modal>
  )
}

export default App
