import React, { useEffect, useState } from "react"
import Button from "react-bootstrap/Button"
import Form from "react-bootstrap/Form"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import { IOption } from "@/lib/interface"
import * as api from "@/lib/api"
import { errorHandle } from "@/lib/system"

function App(props: any) {
  const [weathers, setWeathers] = useState<IOption[]>()
  const [contracts, setContracts] = useState<IOption[]>()
  const [departments, setDepartments] = useState<IOption[]>()
  const [constructions, setConstructions] = useState<IOption[]>()
  const [constructionDetails, setConstructionDetails] = useState<IOption[]>()
  const [operators, setOperators] = useState<IOption[]>()
  const [inspections, setInspections] = useState<IOption[]>()

  useEffect(() => {
    getOptions()
  }, [])

  const getOptions = async () => {
    try {
      const weatherJson: any = await api.getWeathersDb()
      if (weatherJson?.data) {
        setWeathers(weatherJson?.data.json)
      }
      const contractJson: any = await api.getContractsDb()
      if (contractJson?.data) {
        setContracts(contractJson?.data.json)
      }
      const departmentJson: any = await api.getDepartmentsDb()
      if (departmentJson?.data) {
        setDepartments(departmentJson?.data.json)
      }
      const constructionJson: any = await api.getConstructionsDb()
      if (constructionJson?.data) {
        setConstructions(constructionJson?.data.json)
      }
      const constructionDetailJson: any = await api.getConstructionDetailsDb()
      if (constructionDetailJson?.data) {
        setConstructionDetails(constructionDetailJson?.data.json)
      }
      const operatorJson: any = await api.getOperatorsDb()
      if (operatorJson?.data) {
        setOperators(operatorJson?.data.json)
      }
      const inspectionJson: any = await api.getInspectionsDb()
      if (inspectionJson?.data) {
        setInspections(inspectionJson?.data.json)
      }
    } catch (err) {
      console.log(errorHandle(err))
    }
  }

  const handleSearch = async () => {
    props.setProgress(props.Progress.Attempting)
    props.getReports(true)
    props.setProgress(props.Progress.Preparing)
  }

  return (
    <>
      <Row className="align-items-center">
        <Col lg={2} className="fw-bold mb-2 mb-lg-0">
          部門
        </Col>
        <Col lg={10}>
          <Row>
            {departments?.map((department: IOption, index: number) => {
              return (
                <Col xs={6} lg={4} key={`department-${index}`}>
                  <Form.Check
                    inline
                    type="switch"
                    id={`department-${index}`}
                    name="department"
                    label={department.name}
                    value={department.id}
                    checked={props.reportSearch.department_id?.includes(department.id)}
                    onChange={(e) =>
                      props.reportSearchDispatch({
                        type: "DEPARTMENT_ID",
                        payload: e.currentTarget.value,
                        list: departments,
                      })
                    }
                  />
                </Col>
              )
            })}
            <Col xs={6} lg={4}>
              <Form.Check
                inline
                type="switch"
                id={`department-blank`}
                name="department"
                label="入力なし"
                value="blank"
                checked={props.reportSearch.department_id?.includes("blank")}
                onChange={(e) =>
                  props.reportSearchDispatch({
                    type: "DEPARTMENT_ID",
                    payload: e.currentTarget.value,
                    list: departments,
                  })
                }
              />
            </Col>
          </Row>
        </Col>
      </Row>
      <hr />
      <Row className="align-items-center">
        <Col lg={2} className="fw-bold mb-2 mb-lg-0">
          JOB番号
        </Col>
        <Col lg={10}>
          <Row>
            <Form className="col-3 col-lg-3 d-inline-block">
              <Form.Control
                size="sm"
                type="text"
                id="job_category"
                name="job_category"
                value={props.reportSearch.job_category}
                placeholder="上2桁"
                disabled={props.progress === props.Progress.Attempting}
                onChange={(e) =>
                  props.reportSearchDispatch({
                    type: "JOB_CATEGORY",
                    payload: e.currentTarget.value,
                  })
                }
                maxLength={10}
              />
            </Form>
            <div className="col-1 col-lg-1 d-inline-block text-center">-</div>
            <Form className="col-lg d-inline-block">
              <Form.Control
                size="sm"
                type="text"
                id="job_number"
                name="job_number"
                value={props.reportSearch.job_number}
                placeholder="5桁まで"
                disabled={props.progress === props.Progress.Attempting}
                onChange={(e) =>
                  props.reportSearchDispatch({
                    type: "JOB_NUMBER",
                    payload: e.currentTarget.value,
                  })
                }
                maxLength={5}
              />
            </Form>
            <div className="col-1 col-lg-1 d-inline-block text-center">-</div>
            <Form className="col-lg d-inline-block">
              <Form.Control
                size="sm"
                type="text"
                id="job_code1"
                name="job_code1"
                value={props.reportSearch.job_code1}
                placeholder="3桁まで"
                disabled={props.progress === props.Progress.Attempting}
                onChange={(e) =>
                  props.reportSearchDispatch({
                    type: "JOB_CODE1",
                    payload: e.currentTarget.value,
                  })
                }
                maxLength={3}
              />
            </Form>
            <div className="col-1 col-lg-1 d-inline-block text-center">-</div>
            <Form className="col-lg d-inline-block">
              <Form.Control
                size="sm"
                type="text"
                id="job_code2"
                name="job_code2"
                value={props.reportSearch.job_code2}
                placeholder="3桁まで"
                disabled={props.progress === props.Progress.Attempting}
                onChange={(e) =>
                  props.reportSearchDispatch({
                    type: "JOB_CODE2",
                    payload: e.currentTarget.value,
                  })
                }
                maxLength={3}
              />
            </Form>
          </Row>
        </Col>
      </Row>
      <hr />
      <Row className="align-items-center">
        <Col lg={2} className="fw-bold mb-2 mb-lg-0">
          年月日
        </Col>
        <Col lg={10}>
          <Form>
            <Row className="align-items-center">
              <Col xs={5} lg={3}>
                <Form.Control
                  size="sm"
                  type="date"
                  name="job_date_start"
                  id="job_date_start"
                  value={props.reportSearch.job_date_start}
                  placeholder="年月日"
                  disabled={props.progress === props.Progress.Attempting}
                  onChange={(e) =>
                    props.reportSearchDispatch({
                      type: "JOB_DATE_START",
                      payload: e.currentTarget.value,
                    })
                  }
                  maxLength={10}
                />
              </Col>
              <Col xs={2} lg={1} className="text-center">
                〜
              </Col>
              <Col xs={5} lg={3}>
                <Form.Control
                  size="sm"
                  type="date"
                  name="job_date_end"
                  id="job_date_end"
                  value={props.reportSearch.job_date_end}
                  placeholder="年月日"
                  disabled={props.progress === props.Progress.Attempting}
                  onChange={(e) =>
                    props.reportSearchDispatch({
                      type: "JOB_DATE_END",
                      payload: e.currentTarget.value,
                    })
                  }
                  maxLength={10}
                />
              </Col>
            </Row>
          </Form>
        </Col>
      </Row>
      <hr />
      <Row className="align-items-center">
        <Col lg={2} className="fw-bold mb-2 mb-lg-0">
          天候
        </Col>
        <Col lg={10}>
          <Row>
            {weathers?.map((weather: IOption, index: number) => {
              return (
                <Col lg={2} md={4} xs={6} key={`weather-${index}`}>
                  <Form.Check
                    inline
                    type="switch"
                    id={`weather-${index}`}
                    name="weather"
                    label={weather.name}
                    value={weather.id}
                    checked={props.reportSearch.weather_id?.includes(weather.id)}
                    onChange={(e) =>
                      props.reportSearchDispatch({
                        type: "WEATHER_ID",
                        payload: e.currentTarget.value,
                        list: weathers,
                      })
                    }
                  />
                </Col>
              )
            })}
            <Col lg={2} md={4} xs={6}>
              <Form.Check
                inline
                type="switch"
                id={`weather-blank`}
                name="weather"
                label="入力なし"
                value="blank"
                checked={props.reportSearch.weather_id?.includes("blank")}
                onChange={(e) =>
                  props.reportSearchDispatch({
                    type: "WEATHER_ID",
                    payload: e.currentTarget.value,
                    list: weathers,
                  })
                }
              />
            </Col>
          </Row>
        </Col>
      </Row>
      <hr />
      <Row className="align-items-center">
        <Col lg={2} className="fw-bold mb-2 mb-lg-0">
          工事名
        </Col>
        <Col lg={5}>
          <Form>
            <Form.Control
              size="sm"
              type="text"
              id="construction"
              name="construction"
              value={props.reportSearch.construction}
              placeholder="工事名"
              disabled={props.progress === props.Progress.Attempting}
              onChange={(e) =>
                props.reportSearchDispatch({
                  type: "CONSTRUCTION",
                  payload: e.currentTarget.value,
                })
              }
              maxLength={40}
            />
          </Form>
        </Col>
      </Row>
      <hr />
      <Row className="align-items-center">
        <Col lg={2} className="fw-bold mb-2 mb-lg-0">
          請負区分
        </Col>
        <Col lg={10}>
          <Row>
            {contracts?.map((contract: IOption, index: number) => {
              return (
                <Col lg={2} md={4} xs={6} key={`contract-${index}`}>
                  <Form.Check
                    inline
                    type="switch"
                    id={`contract-${index}`}
                    name="contract"
                    label={contract.name}
                    value={contract.id}
                    checked={props.reportSearch.contract_id?.includes(contract.id)}
                    onChange={(e) =>
                      props.reportSearchDispatch({
                        type: "CONTRACT_ID",
                        payload: e.currentTarget.value,
                        list: contracts,
                      })
                    }
                  />
                </Col>
              )
            })}
            <Col lg={2} md={4} xs={6}>
              <Form.Check
                inline
                type="switch"
                id={`contract-blank`}
                name="contract"
                label="入力なし"
                value="blank"
                checked={props.reportSearch.contract_id?.includes("blank")}
                onChange={(e) =>
                  props.reportSearchDispatch({
                    type: "CONTRACT_ID",
                    payload: e.currentTarget.value,
                    list: contracts,
                  })
                }
              />
            </Col>
          </Row>
        </Col>
      </Row>
      <hr />
      <Row className="align-items-center">
        <Col lg={2} className="fw-bold mb-2 mb-lg-0">
          請負金額
        </Col>
        <Col lg={10}>
          <Form>
            <Row className="align-items-center">
              <Col xs={5} lg={3}>
                <Form.Control
                  size="sm"
                  type="text"
                  id="contract_amount_start"
                  name="contract_amount_start"
                  value={props.reportSearch.contract_amount_start}
                  placeholder="請負金額"
                  disabled={props.progress === props.Progress.Attempting}
                  onChange={(e) =>
                    props.reportSearchDispatch({
                      type: "CONTRACT_AMOUNT_START",
                      payload: e.currentTarget.value,
                    })
                  }
                  maxLength={11}
                />
              </Col>
              <Col xs={2} lg={1} className="text-center">
                〜
              </Col>
              <Col xs={5} lg={3}>
                <Form.Control
                  size="sm"
                  type="text"
                  id="contract_amount_end"
                  name="contract_amount_end"
                  value={props.reportSearch.contract_amount_end}
                  placeholder="請負金額"
                  disabled={props.progress === props.Progress.Attempting}
                  onChange={(e) =>
                    props.reportSearchDispatch({
                      type: "CONTRACT_AMOUNT_END",
                      payload: e.currentTarget.value,
                    })
                  }
                  maxLength={11}
                />
              </Col>
            </Row>
          </Form>
        </Col>
      </Row>
      <hr />
      <Row className="align-items-center">
        <Col lg={2} className="fw-bold mb-2 mb-lg-0">
          部署
        </Col>
        <Col lg={5}>
          <Form>
            <div className="text-danger">{props.reportSearch?.errors?.office_name}</div>
            <Form.Control
              size="sm"
              type="text"
              name="office_name"
              id="office_name"
              value={props.reportSearch.office_name}
              placeholder="部署"
              disabled={props.progress === props.Progress.Attempting}
              onChange={(e) =>
                props.reportSearchDispatch({
                  type: "OFFICE_NAME",
                  payload: e.currentTarget.value,
                })
              }
              maxLength={15}
            />
          </Form>
        </Col>
      </Row>
      <hr />
      <Row className="align-items-center">
        <Col lg={2} className="fw-bold mb-2 mb-lg-0">
          工事種類
        </Col>
        <Col lg={10}>
          <Row>
            {constructions?.map((construction: IOption, index: number) => {
              return (
                <Col xs={6} lg={4} key={`construction-${index}`}>
                  <Form.Check
                    inline
                    type="switch"
                    id={`construction-${index}`}
                    name="construction"
                    label={construction.name}
                    value={construction.id}
                    checked={props.reportSearch.construction_id?.includes(construction.id)}
                    onChange={(e) =>
                      props.reportSearchDispatch({
                        type: "CONSTRUCTION_ID",
                        payload: e.currentTarget.value,
                        list: constructions,
                      })
                    }
                  />
                </Col>
              )
            })}
            <Col xs={6} lg={4}>
              <Form.Check
                inline
                type="switch"
                id={`construction-blank`}
                name="construction"
                label="入力なし"
                value="blank"
                checked={props.reportSearch.construction_id?.includes("blank")}
                onChange={(e) =>
                  props.reportSearchDispatch({
                    type: "CONSTRUCTION_ID",
                    payload: e.currentTarget.value,
                    list: constructions,
                  })
                }
              />
            </Col>
          </Row>
        </Col>
      </Row>
      <Row className="align-items-center">
        <Col lg={2} className="fw-bold mb-2 mb-lg-0"></Col>
        <Col lg={10}>
          <hr />
          <Row>
            {constructionDetails?.map((constructionDetail: IOption, index: number) => {
              return (
                <Col xs={6} lg={4} key={`construction-detail-${index}`}>
                  <Form.Check
                    inline
                    type="switch"
                    id={`construction-detail-${index}`}
                    name="construction_detail"
                    label={constructionDetail.name}
                    value={constructionDetail.id}
                    checked={props.reportSearch.construction_detail_id?.includes(constructionDetail.id)}
                    onChange={(e) =>
                      props.reportSearchDispatch({
                        type: "CONSTRUCTION_DETAIL_ID",
                        payload: e.currentTarget.value,
                        list: constructionDetails,
                      })
                    }
                  />
                </Col>
              )
            })}
            <Col xs={6} lg={4}>
              <Form.Check
                inline
                type="switch"
                id={`construction-detail-blank`}
                name="construction_detail"
                label="入力なし"
                value="blank"
                checked={props.reportSearch.construction_detail_id?.includes("blank")}
                onChange={(e) =>
                  props.reportSearchDispatch({
                    type: "CONSTRUCTION_DETAIL_ID",
                    payload: e.currentTarget.value,
                    list: constructionDetails,
                  })
                }
              />
            </Col>
          </Row>
        </Col>
      </Row>
      <hr />
      <Row className="align-items-center">
        <Col lg={2} className="fw-bold mb-2 mb-lg-0">
          部長
        </Col>
        <Col lg={5}>
          <Form>
            <Form.Control
              size="sm"
              type="text"
              name="department_manager"
              id="department_manager"
              value={props.reportSearch.department_manager}
              placeholder="部長"
              disabled={props.progress === props.Progress.Attempting}
              onChange={(e) =>
                props.reportSearchDispatch({
                  type: "DEPARTMENT_MANAGER",
                  payload: e.currentTarget.value,
                })
              }
              maxLength={10}
            />
          </Form>
        </Col>
      </Row>
      <hr />
      <Row className="align-items-center">
        <Col lg={2} className="fw-bold mb-2 mb-lg-0">
          課長
        </Col>
        <Col lg={5}>
          <Form>
            <Form.Control
              size="sm"
              type="text"
              name="section_manager"
              id="section_manager"
              value={props.reportSearch.section_manager}
              placeholder="課長"
              disabled={props.progress === props.Progress.Attempting}
              onChange={(e) =>
                props.reportSearchDispatch({
                  type: "SECTION_MANAGER",
                  payload: e.currentTarget.value,
                })
              }
              maxLength={10}
            />
          </Form>
        </Col>
      </Row>
      <hr />
      <Row className="align-items-center">
        <Col lg={2} className="fw-bold mb-2 mb-lg-0">
          点検者
        </Col>
        <Col lg={5}>
          <Form>
            <Form.Control
              size="sm"
              type="text"
              name="inspector"
              id="inspector"
              value={props.reportSearch.inspector}
              placeholder="点検者"
              disabled={props.progress === props.Progress.Attempting}
              onChange={(e) =>
                props.reportSearchDispatch({
                  type: "INSPECTOR",
                  payload: e.currentTarget.value,
                })
              }
              maxLength={10}
            />
          </Form>
        </Col>
      </Row>
      <hr />
      <Row className="align-items-center">
        <Col lg={2} className="fw-bold mb-2 mb-lg-0">
          被点検者
        </Col>
        <Col lg={5}>
          <Form>
            <Form.Control
              size="sm"
              type="text"
              name="project_manager"
              id="project_manager"
              value={props.reportSearch.project_manager}
              placeholder="現場代理人"
              disabled={props.progress === props.Progress.Attempting}
              onChange={(e) =>
                props.reportSearchDispatch({
                  type: "PROJECT_MANAGER",
                  payload: e.currentTarget.value,
                })
              }
              maxLength={10}
            />
          </Form>
        </Col>
      </Row>
      <hr />
      <Row className="align-items-center">
        <Col lg={2} className="fw-bold mb-2 mb-lg-0">
          当日の作業員
        </Col>
        <Col lg={10}>
          <Row>
            {operators?.map((operator: IOption, index: number) => {
              return (
                <Col xs={6} lg={4} key={`operator-${index}`}>
                  <Form.Check
                    inline
                    type="switch"
                    id={`operator-${index}`}
                    name="operator"
                    label={operator.name}
                    value={operator.id}
                    checked={props.reportSearch.operators?.includes(operator.id)}
                    onChange={(e) =>
                      props.reportSearchDispatch({
                        type: "OPERATOR_ID",
                        payload: e.currentTarget.value,
                        list: operators,
                      })
                    }
                  />
                </Col>
              )
            })}
            <Col lg={2} md={4}>
              <Form.Check
                inline
                type="switch"
                id={`operator-blank`}
                name="operator"
                label="入力なし"
                value="blank"
                checked={props.reportSearch.operators?.includes("blank")}
                onChange={(e) =>
                  props.reportSearchDispatch({
                    type: "OPERATOR_ID",
                    payload: e.currentTarget.value,
                    list: operators,
                  })
                }
              />
            </Col>
          </Row>
        </Col>
      </Row>
      <hr />
      <Row className="align-items-center">
        <Col lg={2} className="fw-bold mb-2 mb-lg-0">
          建築会社
          <br className="d-none d-lg-block" />
          （統括安全衛生責任者）
        </Col>
        <Col lg={5}>
          <Form>
            <Form.Control
              size="sm"
              type="text"
              name="construction_company"
              id="construction_company"
              value={props.reportSearch.construction_company}
              placeholder="建築会社（統括安全衛生責任者）"
              disabled={props.progress === props.Progress.Attempting}
              onChange={(e) =>
                props.reportSearchDispatch({
                  type: "CONSTRUCTION_COMPANY",
                  payload: e.currentTarget.value,
                })
              }
              maxLength={15}
            />
          </Form>
        </Col>
      </Row>
      <hr />
      <Row className="align-items-center">
        <Col lg={2} className="fw-bold mb-2 mb-lg-0">
          当日入場協力会社
        </Col>
        <Col lg={5}>
          <Form>
            <Form.Control
              type="text"
              name="partners"
              id="partners"
              value={props.reportSearch.partners}
              placeholder="当日入場協力会社"
              disabled={props.progress === props.Progress.Attempting}
              onChange={(e) =>
                props.reportSearchDispatch({
                  type: "PARTNER",
                  payload: e.currentTarget.value,
                })
              }
              maxLength={15}
            />
          </Form>
        </Col>
      </Row>
      <hr />
      <Row className="align-items-center">
        <Col lg={2} className="fw-bold mb-2 mb-lg-0">
          総評
        </Col>
        <Col lg={5}>
          <Form>
            <Form.Control
              size="sm"
              type="text"
              name="comment"
              id="comment"
              value={props.reportSearch.comment}
              placeholder="総評"
              disabled={props.progress === props.Progress.Attempting}
              onChange={(e) =>
                props.reportSearchDispatch({
                  type: "COMMENT",
                  payload: e.currentTarget.value,
                })
              }
              maxLength={50}
            />
          </Form>
        </Col>
      </Row>
      <hr />
      <Row className="align-items-center">
        <Col lg={2} className="fw-bold mb-2 mb-lg-0">
          現場からの要望事項
        </Col>
        <Col lg={5}>
          <Form>
            <Form.Control
              size="sm"
              type="text"
              name="request_comment"
              id="request_comment"
              value={props.reportSearch.request_comment}
              placeholder="総評"
              disabled={props.progress === props.Progress.Attempting}
              onChange={(e) =>
                props.reportSearchDispatch({
                  type: "REQUEST_COMMENT",
                  payload: e.currentTarget.value,
                })
              }
              maxLength={50}
            />
          </Form>
        </Col>
      </Row>
      <hr />
      <Row className="align-items-center">
        <Col lg={2} className="fw-bold mb-2 mb-lg-0">
          点検分類
        </Col>
        <Col lg={10}>
          <Row>
            {inspections?.map((inspection: IOption, index: number) => {
              return (
                <Col lg={4} md={6} key={`inspection-${index}`}>
                  <Form.Check
                    inline
                    type="switch"
                    id={`inspection-${index}`}
                    name="inspection"
                    label={inspection.name}
                    value={inspection.id}
                    checked={props.reportSearch.inspections?.includes(inspection.id)}
                    onChange={(e) =>
                      props.reportSearchDispatch({
                        type: "INSPECTION_ID",
                        payload: e.currentTarget.value,
                        list: inspections,
                      })
                    }
                  />
                </Col>
              )
            })}
            <Col lg={4} md={6}>
              <Form.Check
                inline
                type="switch"
                id={`inspection-blank`}
                name="inspection"
                label="入力なし"
                value="blank"
                checked={props.reportSearch.inspections?.includes("blank")}
                onChange={(e) =>
                  props.reportSearchDispatch({
                    type: "INSPECTION_ID",
                    payload: e.currentTarget.value,
                    list: inspections,
                  })
                }
              />
            </Col>
          </Row>
        </Col>
      </Row>
      <hr />
      <Row>
        <Col className="text-center mb-3 mt-4">
          <Button
            className="text-right w-75"
            disabled={props.progress === props.Progress.Attempting}
            onClick={handleSearch}
          >
            検索
          </Button>
        </Col>
      </Row>
    </>
  )
}

export default App
