import React, { useContext, useEffect, useState } from "react"
import { Link, useLocation } from "react-router-dom"
import Nav from "react-bootstrap/Nav"
import { AuthContext } from "@/lib/auth"

function App() {
  const location = useLocation()

  const path = location.pathname
  const home_class = path.match(/^\/$/) ? "active" : ""
  const users_class = path.match(/^\/user/) ? "active" : ""
  const admin_class = path.match(/^\/admin/) ? "active" : ""
  const department_class = path.match(/^\/department/) ? "active" : ""
  const question_class = path.match(/^\/question/) ? "active" : ""
  const report_class = path.match(/^\/report/) ? "active" : ""

  const [currentUser, setCurrentUser] = useState<any>(null)
  const [userAttributes, setUserAttributes] = useState<any>(null)
  const auth = useContext(AuthContext)

  useEffect(() => {
    if (currentUser?.attributes?.identities) {
      const attr = JSON.parse(currentUser?.attributes?.identities)
      setUserAttributes(attr[0])
    }
  }, [currentUser])

  useEffect(() => {
    setCurrentUser(auth.currentUser)
  }, [auth])

  return (
    <>
      <Nav className="flex-column">
        <Nav.Link as={Link} to="/" className={home_class}>
          ホーム
        </Nav.Link>
        <Nav.Link as={Link} to="/admin/" className={admin_class}>
          管理者情報
        </Nav.Link>
        <Nav.Link as={Link} to="/user/" className={users_class}>
          ユーザ情報
        </Nav.Link>
        <Nav.Link as={Link} to="/department/" className={department_class}>
          部門情報
        </Nav.Link>
        <Nav.Link as={Link} to="/question/" className={question_class}>
          チェックリスト
        </Nav.Link>
        <Nav.Link as={Link} to="/report/" className={report_class}>
          用紙一覧
        </Nav.Link>
        <hr />
        {ShowSignoutBtn({
          auth,
          userAttributes,
        })}
      </Nav>
    </>
  )
}

const ShowSignoutBtn = (props: any) => {
  if (props.auth.currentUser) {
    return (
      <Nav.Link as={Link} to={`/auth/signout`} className="text-decoration-underline">
        ログアウト
      </Nav.Link>
    )
  } else {
    return <></>
  }
}

export default App
