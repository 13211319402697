import React, { useState, useEffect, useReducer, useContext } from "react"
import Table from "react-bootstrap/Table"
import ExcelJS from "exceljs"
import Accordion from "react-bootstrap/Accordion"
import { IReportSearch, IOption, IQuestion } from "@/lib/interface"
import * as api from "@/lib/api"
import { AuthContext } from "@/lib/auth"
import { changeDateString } from "@/lib/system"
import FormSearch from "./_form-search"
import { useTranslation } from "react-i18next"

enum Progress {
  Preparing,
  Attempting,
}
//本日の日付を取得し、本年と翌年の1月1日を格納
const today = new Date()
const jobDateStartDefault = new Date(`${today.getFullYear()}/01/01`)
const jobDateEndDefault = new Date(`${today.getFullYear() + 1}/01/01`)

//検索フォームのデフォルト値を設定
const initialReportSearch = {
  job_category: "",
  job_number: "",
  job_date_start: changeDateString(jobDateStartDefault),
  job_date_end: changeDateString(jobDateEndDefault),
  weather_id: new Array<string>(),
  construction: "",
  contract_id: new Array<string>(),
  contract_amount_start: "",
  contract_amount_end: "",
  department_id: new Array<string>(),
  office_name: "",
  construction_id: new Array<string>(),
  construction_detail_id: new Array<string>(),
  inspector: "",
  department_manager: "",
  section_manager: "",
  project_manager: "",
  operators: new Array<string>(),
  construction_company: "",
  partners: "",
  comment: "",
  request_comment: "",
  inspections: new Array<string>(),
}

type ACTIONTYPE =
  | { type: "JOB_CATEGORY"; payload: string }
  | { type: "JOB_NUMBER"; payload: string }
  | { type: "JOB_DATE_START"; payload: string }
  | { type: "JOB_DATE_END"; payload: string }
  | { type: "WEATHER_INIT"; payload: string; list: IOption[] }
  | { type: "WEATHER_ID"; payload: string; list: IOption[] }
  | { type: "CONSTRUCTION"; payload: string }
  | { type: "CONTRACT_INIT"; payload: string; list: IOption[] }
  | { type: "CONTRACT_ID"; payload: string; list: IOption[] }
  | { type: "CONTRACT_AMOUNT_START"; payload: string }
  | { type: "CONTRACT_AMOUNT_END"; payload: string }
  | { type: "DEPARTMENT_INIT"; payload: string; list: IOption[] }
  | { type: "DEPARTMENT_ID"; payload: string; list: IOption[] }
  | { type: "OFFICE_NAME"; payload: string }
  | { type: "CONSTRUCTION_ID"; payload: string; list: IOption[] }
  | { type: "CONSTRUCTION_DETAIL_ID"; payload: string; list: IOption[] }
  | { type: "INSPECTOR"; payload: string }
  | { type: "DEPARTMENT_MANAGER"; payload: string }
  | { type: "SECTION_MANAGER"; payload: string }
  | { type: "PROJECT_MANAGER"; payload: string }
  | { type: "OPERATOR_INIT"; payload: string; list: IOption[] }
  | { type: "OPERATOR_ID"; payload: string; list: IOption[] }
  | { type: "CONSTRUCTION_COMPANY"; payload: string }
  | { type: "PARTNER"; payload: string }
  | { type: "COMMENT"; payload: string }
  | { type: "INSPECTION_INIT"; payload: string; list: IOption[] }
  | { type: "INSPECTION_ID"; payload: string; list: IOption[] }

const reducerFunc: React.Reducer<IReportSearch, ACTIONTYPE> = (
  reportSearch: IReportSearch,
  action: ACTIONTYPE
): IReportSearch => {
  switch (action.type) {
    // JOB番号 上2桁が変更された時
    case "JOB_CATEGORY":
      return { ...reportSearch, job_category: action.payload }
    // JOB番号 下11桁が変更された時
    case "JOB_NUMBER":
      return { ...reportSearch, job_number: action.payload }
    // 年月日が変更された時
    case "JOB_DATE_START":
      return { ...reportSearch, job_date_start: action.payload }
    // 年月日が変更された時
    case "JOB_DATE_END":
      return { ...reportSearch, job_date_end: action.payload }
    // 天候が変更された時
    case "WEATHER_ID": {
      const weathers = action.list?.map((weather: IOption) => {
        return weather.id
      })
      if (weathers.includes(action.payload) || action.payload === "blank") {
        let updateWeathers = []
        // desabledにされた場合
        if (
          reportSearch.weather_id?.find((weather: string) => {
            return weather === action.payload
          })
        ) {
          updateWeathers = reportSearch.weather_id.filter((weather: string) => {
            return weather !== action.payload
          })
          return { ...reportSearch, weather_id: updateWeathers }
          // enabledにされた場合
        } else {
          return {
            ...reportSearch,
            weather_id: [...(reportSearch.weather_id || []), action.payload],
          }
        }
      } else {
        //選択肢以外の値が入れられた場合には更新しない
        return reportSearch
      }
    }
    // 工事名が変更された時
    case "CONSTRUCTION":
      return { ...reportSearch, construction: action.payload }
    // 請負区分が変更された時
    case "CONTRACT_ID": {
      const contracts = action.list?.map((contract: IOption) => {
        return contract.id
      })
      if (contracts.includes(action.payload) || action.payload === "blank") {
        let updateContracts = []
        // desabledにされた場合
        if (
          reportSearch.contract_id?.find((contract: string) => {
            return contract === action.payload
          })
        ) {
          updateContracts = reportSearch.contract_id.filter((contract: string) => {
            return contract !== action.payload
          })
          return { ...reportSearch, contract_id: updateContracts }
          // enabledにされた場合
        } else {
          return {
            ...reportSearch,
            contract_id: [...(reportSearch.contract_id || []), action.payload],
          }
        }
      } else {
        //選択肢以外の値が入れられた場合には更新しない
        return reportSearch
      }
    }
    // 請負金額が変更された時
    case "CONTRACT_AMOUNT_START":
      return { ...reportSearch, contract_amount_start: action.payload }
    case "CONTRACT_AMOUNT_END":
      return { ...reportSearch, contract_amount_end: action.payload }
    // 部門が変更された時
    case "DEPARTMENT_ID": {
      const departments = action.list?.map((department: IOption) => {
        return department.id
      })
      if (departments.includes(action.payload) || action.payload === "blank") {
        let updateDepartments = []
        // desabledにされた場合
        if (
          reportSearch.department_id?.find((department: string) => {
            return department === action.payload
          })
        ) {
          updateDepartments = reportSearch.department_id.filter((department: string) => {
            return department !== action.payload
          })
          return { ...reportSearch, department_id: updateDepartments }
          // enabledにされた場合
        } else {
          return {
            ...reportSearch,
            department_id: [...(reportSearch.department_id || []), action.payload],
          }
        }
      } else {
        //選択肢以外の値が入れられた場合には更新しない
        return reportSearch
      }
    }
    // 部署が変更された時
    case "OFFICE_NAME":
      return { ...reportSearch, office_name: action.payload }
    // 工事種類が変更された時
    case "CONSTRUCTION_ID": {
      const constructions = action.list?.map((construction: IOption) => {
        return construction.id
      })
      if (constructions.includes(action.payload) || action.payload === "blank") {
        let updateConstructions = []
        // desabledにされた場合
        if (
          reportSearch.construction_id?.find((construction: string) => {
            return construction === action.payload
          })
        ) {
          updateConstructions = reportSearch.construction_id.filter((construction: string) => {
            return construction !== action.payload
          })
          return { ...reportSearch, construction_id: updateConstructions }
          // enabledにされた場合
        } else {
          return {
            ...reportSearch,
            construction_id: [...(reportSearch.construction_id || []), action.payload],
          }
        }
      } else {
        //選択肢以外の値が入れられた場合には更新しない
        return reportSearch
      }
    }
    // 工事種類その2が変更された時
    case "CONSTRUCTION_DETAIL_ID": {
      const constructionDetails = action.list?.map((constructionDetail: IOption) => {
        return constructionDetail.id
      })
      if (constructionDetails.includes(action.payload) || action.payload === "blank") {
        let updateConstructionDetails = []
        // desabledにされた場合
        if (
          reportSearch.construction_detail_id?.find((constructionDetail: string) => {
            return constructionDetail === action.payload
          })
        ) {
          updateConstructionDetails = reportSearch.construction_detail_id.filter((constructionDetail: string) => {
            return constructionDetail !== action.payload
          })
          return {
            ...reportSearch,
            construction_detail_id: updateConstructionDetails,
          }
          // enabledにされた場合
        } else {
          return {
            ...reportSearch,
            construction_detail_id: [...(reportSearch.construction_detail_id || []), action.payload],
          }
        }
      } else {
        //選択肢以外の値が入れられた場合には更新しない
        return reportSearch
      }
    }
    // 点検者が変更された時
    case "INSPECTOR":
      return { ...reportSearch, inspector: action.payload }
    // 部長が変更された時
    case "DEPARTMENT_MANAGER":
      return { ...reportSearch, department_manager: action.payload }
    // 課長が変更された時
    case "SECTION_MANAGER":
      return { ...reportSearch, section_manager: action.payload }
    // が変更された時
    case "PROJECT_MANAGER":
      return { ...reportSearch, project_manager: action.payload }
    // 工事責任者が変更された時
    case "OPERATOR_ID": {
      const operators = action.list?.map((operator: IOption) => {
        return operator.id
      })
      if (operators.includes(action.payload) || action.payload === "blank") {
        let updateOperators = []
        // desabledにされた場合
        if (
          reportSearch.operators?.find((operator: string) => {
            return operator === action.payload
          })
        ) {
          updateOperators = reportSearch.operators.filter((operator: string) => {
            return operator !== action.payload
          })
          return { ...reportSearch, operators: updateOperators }
          // enabledにされた場合
        } else {
          return {
            ...reportSearch,
            operators: [...(reportSearch.operators || []), action.payload],
          }
        }
      } else {
        //選択肢以外の値が入れられた場合には更新しない
        return reportSearch
      }
    }
    // 建築会社（統括安全衛生責任者）が変更された時
    case "CONSTRUCTION_COMPANY":
      return { ...reportSearch, construction_company: action.payload }
    // 当日入場協力会社が変更された時
    case "PARTNER":
      return { ...reportSearch, partners: action.payload }
    // コメントが変更された時
    case "COMMENT":
      return { ...reportSearch, comment: action.payload }
    // 点検分類が変更された時
    case "INSPECTION_ID": {
      const inspectionIds = action.list?.map((inspection: IOption) => {
        return inspection.id
      })
      //選択された項目が作業分類の一覧に合致する場合
      if (inspectionIds.includes(action.payload)) {
        let updateInspections = []
        // desabledにされた場合
        if (
          reportSearch.inspections?.find((inspection: string) => {
            return inspection === action.payload
          })
        ) {
          updateInspections = reportSearch.inspections.filter((inspection: string) => {
            return inspection !== action.payload
          })
          return { ...reportSearch, inspections: updateInspections }
          // enabledにされた場合
        } else {
          return {
            ...reportSearch,
            inspections: [...(reportSearch.inspections || []), action.payload],
          }
        }
      } else {
        //選択肢以外の値が入れられた場合には更新しない
        return reportSearch
      }
    }
    default:
      return reportSearch
  }
}

function App() {
  const { t } = useTranslation()
  const auth = useContext(AuthContext)
  const [questions, setQuestions] = useState<IQuestion[]>([])
  const [progress, setProgress] = useState(Progress.Preparing)
  const [reportSearch, reportSearchDispatch] = useReducer<React.Reducer<IReportSearch, ACTIONTYPE>>(
    reducerFunc,
    initialReportSearch
  )

  useEffect(() => {
    getSelect()
  }, [])

  const getSelect = async () => {
    try {
      const questionJson: any = await api.getTotalResultDb(reportSearch)
      if (questionJson?.data.json) {
        setQuestions(questionJson.data.json)
      }
    } catch (err) {
      console.log(err)
    }
  }

  const handlerCsvDownload = async () => {
    const workbook = new ExcelJS.Workbook()
    workbook.addWorksheet("sheet1")
    const worksheet = workbook.getWorksheet("sheet1")
    //ヘッドライン生成 start
    const headLine = [
      { header: "作業分類 / 部門", key: "ins_dep" },
      { header: "大項目", key: "class_name" },
      { header: "チェック内容", key: "name" },
      { header: t("RESULT_SLASH"), key: "RESULT_SLASH" },
      { header: t("RESULT_CIRCLE"), key: "RESULT_CIRCLE" },
      { header: t("RESULT_TRIANGLE"), key: "RESULT_TRIANGLE" },
      { header: t("RESULT_CROSS"), key: "RESULT_CROSS" },
    ]
    //ヘッドライン生成 end
    worksheet.columns = headLine
    questions?.map((question: IQuestion) => {
      //作業分類か部門のどちらか
      const insDep = question.inspection_name || question.department_name
      //データライン生成 start
      const dataLine = [
        {
          ins_dep: insDep,
          class_name: question.class_name,
          name: question.name,
          RESULT_SLASH: question.RESULT_SLASH,
          RESULT_CIRCLE: question.RESULT_CIRCLE,
          RESULT_TRIANGLE: question.RESULT_TRIANGLE,
          RESULT_CROSS: question.RESULT_CROSS,
        },
      ]
      //データライン生成 end
      worksheet.addRows(dataLine)
    })
    //文字コードをexel用に変換
    const uint8Array = await workbook.xlsx.writeBuffer()
    const blob = new Blob([uint8Array], {
      type: "application/octet-binary",
    })
    const url = window.URL.createObjectURL(blob)
    const a = document.createElement("a")
    a.href = url
    a.download = "total_check_sheet.xlsx"
    a.click()
    a.remove()
  }

  return (
    <React.Fragment>
      <h1 className="h4">{reportSearch.job_number}管理者情報マイページ</h1>
      <hr />
      <div className="row mb-5">
        <div className="fw-bold col-12 col-md-3">ログインID（メールアドレス）</div>
        <div className="col-12 col-md-9">{auth.currentUser?.attributes?.email}</div>
      </div>
      <h1 className="h4">安全パトロール用紙集計</h1>
      <div className="row">
        <div className="col-12">
          <Accordion>
            <Accordion.Item eventKey="0">
              <Accordion.Header>絞り込み</Accordion.Header>
              <Accordion.Body className="bg-light">
                <FormSearch
                  getSelect={getSelect}
                  reportSearchDispatch={reportSearchDispatch}
                  reportSearch={reportSearch}
                  Progress={Progress}
                  progress={progress}
                  setProgress={setProgress}
                />
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
          <hr />
        </div>
      </div>
      {questions?.length > 0 && (
        <button onClick={() => handlerCsvDownload()} className="btn btn-info text-white mb-4">
          Excel用CSVダウンロード
        </button>
      )}
      <div>
        <Table striped>
          <thead className="text-center bg-dark text-light">
            <tr>
              <th className="text-center"></th>
              <th className="text-center"></th>
              <th className="">作業分類 / 部門</th>
              <th className="">大項目</th>
              <th className="">チェック内容</th>
              <th className="">{t("RESULT_SLASH")}</th>
              <th className="">{t("RESULT_CIRCLE")}</th>
              <th className="">{t("RESULT_TRIANGLE")}</th>
              <th className="">{t("RESULT_CROSS")}</th>
            </tr>
          </thead>
          <tbody>
            {questions?.map((question: IQuestion, questionIndex: number) => {
              return (
                <tr key={`question-${questionIndex}`}>
                  <td className="align-middle text-center">{questionIndex + 1}.</td>
                  <td className="align-middle text-center">
                    {question.status !== 1 && (
                      <span className="bg-secondary text-light small p-1 rounded text-nowrap">非表示</span>
                    )}
                    {question.status === 1 && (
                      <span className="bg-primary text-light small p-1 rounded text-nowrap">表示中</span>
                    )}
                  </td>
                  <td className="align-middle text-nowrap">
                    {question.inspection_name}
                    {question.department_name}
                  </td>
                  <td className="align-middle text-break">{question.class_name}</td>
                  <td className="align-middle text-break">{question.name}</td>
                  <td className="text-center align-middle">{question.RESULT_SLASH}</td>
                  <td className="text-center align-middle">{question.RESULT_CIRCLE}</td>
                  <td className="text-center align-middle">{question.RESULT_TRIANGLE}</td>
                  <td className="text-center align-middle">{question.RESULT_CROSS}</td>
                </tr>
              )
            })}
          </tbody>
        </Table>
      </div>
    </React.Fragment>
  )
}

export default App
